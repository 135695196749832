import { useCallback, useContext } from 'react';
import { Button } from '@karnott/buttons';
import { I18nContext } from '../contexts/I18nProvider';
import { UIContext } from '../contexts/ui';
import Modal, { ModalBody, ModalFooter, ModalLink, ModalTitle } from './Modal';

const HelpDemoModal = function () {
  const { t } = useContext(I18nContext);
  const { closeDemoModal, demoModalOpened } = useContext(UIContext);

  const handleOk = useCallback(
    (e) => {
      e.preventDefault();
      closeDemoModal();
    },
    [closeDemoModal],
  );

  return (
    <Modal contentLabel="helpDemoModal" {...{ onRequestClose: closeDemoModal, isOpen: demoModalOpened }}>
      <ModalTitle>{t('HelpDemoModal.title')}</ModalTitle>
      <ModalBody>
        {t('HelpDemoModal.explanation_1')}
        <ModalLink href="tel:+33374094892">+33 (0)3 74 09 48 92</ModalLink>
        {t('HelpDemoModal.explanation_2')}
        <ModalLink href="mailto:support@karnott.fr">support@karnott.fr</ModalLink>
        {t('HelpDemoModal.explanation_3')}
      </ModalBody>
      <ModalFooter>
        <Button title={t('HelpDemoModal.ok')} onClick={handleOk} success />
      </ModalFooter>
    </Modal>
  );
};

export default HelpDemoModal;
