import { Actions } from '../actions';

const initialState = {
  products: [],
  mixtures: [],
};

const phyto = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.GET_PHYTO_FAVORITE_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.clusterId]: [
            ...(state.products[payload.clusterId] || []).filter((p) => p.id !== payload.product.id),
            payload.product,
          ],
        },
      };
    case Actions.GET_PHYTO_FAVORITE_PRODUCTS:
      return {
        ...state,
        products: { ...state.products, [payload.clusterId]: payload.products },
      };
    case Actions.DELETE_PHYTO_PRODUCT: {
      const clusterProducts = state.products[payload.clusterId];
      const newProducts = clusterProducts.filter((p) => p.id !== payload.id);
      return {
        ...state,
        products: { ...state.products, [payload.clusterId]: newProducts },
      };
    }
    case Actions.GET_PHYTO_MIXTURES:
      return {
        ...state,
        mixtures: { ...state.mixtures, [payload.clusterId]: payload.mixtures },
      };
    case Actions.UPDATE_PHYTO_MIXTURE:
      return {
        ...state,
        mixtures: state.mixtures.map((mixture) => (mixture.id === payload.mixture.id ? payload.mixture : mixture)),
      };
    default:
      return state;
  }
};

export default phyto;
