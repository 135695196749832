export const EQUIPMENT_CONSTANTS = {
  FORM: {
    WIDTH_INPUT_POPOVER_TEXT: 'Non disponible pour un tracteur ou une remorque',
  },
  TYPES: {
    andaineuse: 'andaineuse',
    broy: 'broy',
    charrue: 'charrue',
    chenillard: 'chenillard',
    coupe: 'coupe',
    enjambeur: 'enjambeur',
    epand: 'epand',
    faneuse: 'faneuse',
    faucheuse: 'faucheuse',
    haymaking: 'haymaking',
    intercep: 'intercep',
    lisier: 'lisier',
    misc: 'misc',
    plomb: 'plomb',
    presse: 'presse',
    pulve: 'pulve',
    recolt: 'recolt',
    recolt_tool: 'recolt_tool',
    remork: 'remork',
    semoir: 'semoir',
    snow_removal: 'snow_removal',
    sol_combined: 'sol_combined',
    sol: 'sol',
    tractor: 'tractor',
    traitement: 'traitement',
    vendange: 'vendange',
    weeding: 'weeding',
  },
  CATEGORIES: {
    grande_culture: [
      'andaineuse',
      'broy',
      'charrue',
      'epand',
      'faneuse',
      'faucheuse',
      'haymaking',
      'lisier',
      'misc',
      'plomb',
      'presse',
      'pulve',
      'recolt',
      'remork',
      'semoir',
      'sol_combined',
      'sol',
      'tractor',
    ],
    viticulture: [
      'broy',
      'chenillard',
      'charrue',
      'coupe',
      'enjambeur',
      'epand',
      'intercep',
      'misc',
      'remork',
      'semoir',
      'sol_combined',
      'tractor',
      'traitement',
      'vendange',
      'weeding',
    ],
    deneigement: ['snow_removal'],
  },
  BILLING_METHODS: {
    DISTANCE: 'DISTANCE',
    DISTANCE_IN_FIELD: 'DISTANCE_IN_FIELD',
    DISTANCE_OUT_FIELD: 'DISTANCE_OUT_FIELD',
    AREA: 'AREA',
    TIME: 'TIME',
    TIME_MOVEMENT: 'TIME_MOVEMENT',
    TIME_IN_FIELD: 'TIME_IN_FIELD',
    TIME_OUT_FIELD: 'TIME_OUT_FIELD',
    DURATION: 'DURATION',
    UNIT: 'UNIT',
  },
  GPIO_METHODS: {
    UNIT: 'unit',
    TIME: 'time',
  },
  EQUIPMENT_PAGE: {
    TAB: {
      SESSIONS: 'sessions',
      CONSUMPTIONS: 'consumptions',
      OBSERVATIONS: 'observations',
    },
  },
  EQUIPMENT_STATUS: {
    AVAILABLE: 'AVAILABLE',
    INUSE: 'IN_USE',
  },
} as const;

export const BILLING_METHOD_TO_PROPERTY_NAME = {
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.AREA]: 'area',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME]: 'motorDuration',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_IN_FIELD]: 'inParcelMotorDuration',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_OUT_FIELD]: 'outParcelMotorDuration',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.TIME_MOVEMENT]: 'movementDuration',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.DURATION]: 'duration',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE]: 'distance',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_IN_FIELD]: 'inParcelDistance',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.DISTANCE_OUT_FIELD]: 'outParcelDistance',
  [EQUIPMENT_CONSTANTS.BILLING_METHODS.UNIT]: 'unit',
} as const;

export const getEquipmentTypesList = () => Object.values(EQUIPMENT_CONSTANTS.TYPES);
