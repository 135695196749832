import { ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { pixelSize, pixelSpacing, zIndex } from '@karnott/theme';

type Orientation = 'top' | 'bottom' | 'left' | 'right';

type TooltipMessageProps = {
  /** Content of the tooltip */
  message?: string;
  /** Sets the orientation of the message */
  orientation?: Orientation;
  /** Prevents the message from wrapping */
  noWrap?: boolean;
  /** Shows a tooltip with a smaller font size */
  small?: boolean;
  /** Don’t apply any style to the content of the tooltip */
  unstyledContent?: boolean;
};

type TooltipProps = TooltipMessageProps & {
  /** Content that opens the tooltip on hover */
  content?: ReactNode;
};

/** Display helpful information on hover */
function Tooltip({
  content,
  message,
  orientation = 'top',
  noWrap = false,
  small = false,
  unstyledContent = false,
}: TooltipProps) {
  const [ref, hovered] = UIHooks.useHover<HTMLDivElement>();
  const defaultContent: ReactNode = useMemo(() => (content ? content : '?'), [content]);
  return (
    <TooltipContainer ref={ref} $content={defaultContent} unstyled={unstyledContent}>
      {defaultContent}
      {message && hovered ? (
        <TooltipMessage orientation={orientation} message={message} noWrap={noWrap} small={small} />
      ) : null}
    </TooltipContainer>
  );
}

/** Tooltip component alone */
function TooltipMessage({ message, orientation = 'top', noWrap = false, small = false }: TooltipMessageProps) {
  return (
    <TooltipMessageContainer $orientation={orientation}>
      <Message $noWrap={noWrap} small={small}>
        {message}
      </Message>
    </TooltipMessageContainer>
  );
}

const TooltipContainer = styled.div<{
  $content: ReactNode;
  unstyled: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ unstyled, $content }) =>
    unstyled
      ? null
      : css`
          color: ${colors('white')};
          background-color: ${colors('black')};
          font-size: 10px;
          border-radius: ${typeof $content === 'string' && $content?.length > 1 ? '5px' : '50%'};
          padding: ${typeof $content === 'string' && $content?.length > 1
            ? pixelSpacing('xSmall')
            : `${pixelSpacing('xSmall')} ${pixelSpacing('small')}`};
        `}
`;

const TooltipMessageContainer = styled.div<{
  $orientation: Orientation;
}>`
  position: absolute;
  display: flex;
  width: ${({ $orientation }) => ($orientation === 'top' || $orientation === 'bottom' ? '100%' : 'auto')};
  height: ${({ $orientation }) => ($orientation === 'left' || $orientation === 'right' ? '100%' : 'auto')};
  left: ${({ $orientation }) => ($orientation === 'left' ? 'inherit' : '0px')};
  align-items: flex-start;
  justify-content: center;
  z-index: ${zIndex('sky')};
  pointer-events: none;

  ${({ $orientation }) => {
    switch ($orientation) {
      case 'top':
        return 'bottom: calc(100% + 3px);';
      case 'bottom':
        return 'top: calc(100% + 3px);';
      case 'left':
        return 'right: calc(100% + 12px);';
      case 'right':
        return 'left: calc(100% + 12px);';
    }
  }}
`;

const Message = styled.span<{
  $noWrap: boolean;
  small: boolean;
}>`
  position: relative;
  color: ${colors('white')};
  background-color: ${colors('black')};
  border-radius: 4px;
  border-color: ${colors('white')};
  padding: 3px ${({ small }) => (small ? 5 : 6)}px;
  white-space: ${({ $noWrap }) => ($noWrap ? 'nowrap' : 'pre-line')};
  display: flex;
  width: auto;
  font-size: ${({ small }) => (small ? '10px' : pixelSize('small'))};
  text-align: left;
`;

export { Tooltip, TooltipMessage };
