import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { initializeApp } from 'firebase/app';
import 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import mixpanel from 'mixpanel-browser';
import 'rc-slider/assets/index.css';
import 'sanitize.css/sanitize.css';
import WebFont from 'webfontloader';
import { saveState } from './api/localStorage';
import { MapProvider } from './components/map/contexts/map';
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_PROJECT_ID,
  MIXPANEL_KEY,
} from './constants';
import { GOOGLE_MUTANT_TILES, IGN_LAYER } from './constants/mapConstants';
import AccessToken from './containers/AccessTokenContainer';
import App from './containers/AppContainer';
import ShareContainer from './containers/ShareContainer';
import I18nProvider from './contexts/I18nProvider';
import SnacksProvider from './contexts/SnacksProvider';
import { BinariesProvider } from './contexts/binaries';
import { ClustersProvider } from './contexts/clusters';
import { PermissionsProvider } from './contexts/permissions';
import { PluginsProvider } from './contexts/plugins';
import { UIProvider } from './contexts/ui';
import store, { history } from './reducers/store';
import './stylesheets/slider.css';
import './stylesheets/style.css';
import { defaultModalStyles } from './ui/theme';
import WithAnalytics from './utils/Analytics';
import { RemoteConfig, RemoteConfigSwitch } from './utils/RemoteConfig';
import Sentry from './utils/Sentry';
import { localStorageKeys } from './utils/storage';

// Missing globals
window.global = window;
// https://github.com/Leaflet/Leaflet.draw/issues/1026
window.type = '';

if (import.meta.env.PROD) {
  // MIXPANEL
  mixpanel.init(MIXPANEL_KEY, { debug: false, track_pageview: true });

  // SENTRY
  Sentry.getInstance().init();

  // Init Firebase
  const config = {
    appId: FIREBASE_APP_ID,
    apiKey: FIREBASE_API_KEY,
    projectId: FIREBASE_PROJECT_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
  };

  initializeApp(config);
  const r = indexedDB.open('ping', 1);
  r.onsuccess = function () {
    const remoteConfig = getRemoteConfig();
    RemoteConfig.getInstance().setConfig(remoteConfig);
    indexedDB.deleteDatabase('ping');
  };
  r.onerror = function () {
    return false;
  };
}

store.subscribe(() => {
  const state = store.getState();
  const { user } = state;
  saveState({ user });
});

// Unregister existing Service Worker
if (navigator.serviceWorker) {
  navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    })
    .catch((e) => console.log(e));
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <I18nProvider>
      <RemoteConfigSwitch>
        <UIProvider>
          <PermissionsProvider>
            <PluginsProvider>
              <MapProvider
                tiles={localStorage.getItem(localStorageKeys.ign) === 'true' ? IGN_LAYER : GOOGLE_MUTANT_TILES}
              >
                <SnacksProvider>
                  <BinariesProvider>
                    <ClustersProvider>
                      <ConnectedRouter history={history}>
                        <Switch>
                          <Route path="/oauth_access_token" component={WithAnalytics(AccessToken)} />
                          <Route path="/share" component={WithAnalytics(ShareContainer)} />
                          <Route component={WithAnalytics(App)} />
                        </Switch>
                      </ConnectedRouter>
                    </ClustersProvider>
                  </BinariesProvider>
                </SnacksProvider>
              </MapProvider>
            </PluginsProvider>
          </PermissionsProvider>
        </UIProvider>
      </RemoteConfigSwitch>
    </I18nProvider>
  </Provider>,
);

WebFont.load({
  google: {
    families: ['Montserrat:400,700,800,800i', 'Open Sans:400,600,700', 'Ubuntu:100,200,300,400,400i,500,600,700,700i'],
  },
});

Modal.defaultStyles = defaultModalStyles;
