import { Actions } from '../actions';

const initialState = {
  isFetching: false,
  unitLocationsByEquipmentSessionId: {},
  unitLocationsByParcelSessionId: {},
};

const unitLocations = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.EQUIPMENT_SESSION_UNIT_LOCATIONS_REQUEST:
      return { ...state, isFetching: true };
    case Actions.EQUIPMENT_SESSION_UNIT_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        unitLocationsByEquipmentSessionId:
          {
            ...state.unitLocationsByEquipmentSessionId,
            ...{ [payload.equipmentSessionId]: payload.unitLocations },
          } || {},
      };
    case Actions.PARCEL_SESSION_UNIT_LOCATIONS_REQUEST:
      return { ...state, isFetching: true };
    case Actions.PARCEL_SESSION_UNIT_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        unitLocationsByParcelSessionId:
          {
            ...state.unitLocationsByParcelSessionId,
            ...{ [payload.parcelSessionId]: payload.unitLocations },
          } || {},
      };
    default:
      return state;
  }
};

export default unitLocations;
