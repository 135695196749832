import find from 'lodash-es/find';
import omit from 'lodash-es/omit';
import { Actions } from '../actions';
import { OBSERVATION_CONSTANTS } from '../constants/observationsConstants';

const initialState = {
  areEquipmentReportsFetching: false,
  areParcelsReportsFetching: false,
  areEquipmentsReportsTracksFetching: false,
  areParcelsReportsTracksFetching: false,
  areEquipmentsReportsTracksInvalid: false,
  areParcelsReportsTracksInvalid: false,
  equipmentsReportsClusterIdsAndName: [],
  equipmentsReports: [],
  parcelsReportsMeta: [],
  parcelsReports: [],
  observationIDs: [],
  observationByID: {},
  observationsFetching: false,
  equipmentsPreferredDimension: {},
  parcelsPreferredDimension: {},
  worksites: [],
  worksiteInitialEquipmentModel: null,
  worksiteByClusterInitialCluster: null,
};

const reports = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.REPORTS_EQUIPMENTS_REPORT_SUCCESS: {
      const erCamelCasedData = {};
      const equipmentsReportsClusterIdsAndName = [];
      (payload || []).forEach((equipmentReport) => {
        if (
          !find(equipmentsReportsClusterIdsAndName, {
            clusterId: equipmentReport.cluster_id,
          })
        ) {
          equipmentsReportsClusterIdsAndName.push({
            clusterId: equipmentReport.cluster_id,
            clusterName: equipmentReport.cluster_name,
          });
        }
        const uniqueKey = `${equipmentReport.equipment_id},${equipmentReport.cluster_id}`;
        erCamelCasedData[uniqueKey] = {
          equipmentId: equipmentReport.equipment_id,
          equipmentName: equipmentReport.equipment_name,
          equipmentBillingMethod: equipmentReport.equipment_billing_method,
          clusterId: equipmentReport.cluster_id,
          clusterName: equipmentReport.cluster_name,
          totalArea: equipmentReport.total_area,
          totalDistance: equipmentReport.total_distance,
          totalDuration: equipmentReport.total_duration,
          totalInParcelsDistance: equipmentReport.total_in_parcels_distance,
          totalInParcelsMotorDuration: equipmentReport.total_in_parcels_motor_duration,
          totalMotorDuration: equipmentReport.total_motor_duration,
          totalMovementDuration: equipmentReport.total_movement_duration,
          totalOutOfParcelsDistance: equipmentReport.total_out_of_parcels_distance,
          totalOutOfParcelsMotorDuration: equipmentReport.total_out_of_parcels_motor_duration,
          totalUnit: equipmentReport.total_unit,
        };
      });
      return {
        ...state,
        areEquipmentReportsFetching: false,
        equipmentsReportsClusterIdsAndName,
        equipmentsReports: erCamelCasedData,
      };
    }
    case Actions.REPORTS_PARCELS_REPORT_SUCCESS: {
      const prCamelCasedData = {};
      const parcelsReportsMeta = {};
      (payload || []).forEach((parcelReport) => {
        const uniqueKey = `${parcelReport.parcel_id},${parcelReport.equipment_id}`;
        if (!parcelsReportsMeta[parcelReport.parcel_id]) {
          parcelsReportsMeta[parcelReport.parcel_id] = {
            parcelId: parcelReport.parcel_id,
            parcelName: parcelReport.parcel_name,
            parcelArea: parcelReport.parcel_area,
            clusterName: parcelReport.cluster_owner_name_of_parcel ?? parcelReport.cluster_name,
            clusterId: parcelReport.cluster_id,
          };
        }
        prCamelCasedData[uniqueKey] = {
          parcelId: parcelReport.parcel_id,
          parcelName: parcelReport.parcel_name,
          parcelArea: parcelReport.parcel_area,
          equipmentId: parcelReport.equipment_id,
          equipmentName: parcelReport.equipment_name,
          totalArea: parcelReport.total_area,
          totalDistance: parcelReport.total_distance,
          totalDuration: parcelReport.total_duration,
          totalMotorDuration: parcelReport.total_motor_duration,
          totalMovementDuration: parcelReport.total_movement_duration,
          numberOfSession: parcelReport.number_of_session,
          totalUnit: parcelReport.total_unit,
        };
      });
      return {
        ...state,
        areParcelsReportsFetching: false,
        parcelsReportsMeta,
        parcelsReports: prCamelCasedData,
      };
    }
    case Actions.REPORTS_EQUIPMENTS_REPORT_REQUEST:
      return {
        ...state,
        areEquipmentReportsFetching: true,
      };
    case Actions.REPORTS_PARCELS_REPORT_REQUEST:
      return {
        ...state,
        areParcelsReportsFetching: true,
      };
    case Actions.REPORTS_EQUIPMENTS_TRACKS_REPORT_REQUEST:
      return {
        ...state,
        areEquipmentsReportsTracksFetching: true,
        areEquipmentsReportsTracksInvalid: false,
        equipmentsReportsTracks: null,
      };
    case Actions.REPORTS_PARCELS_TRACKS_REPORT_REQUEST:
      return {
        ...state,
        areParcelsReportsTracksFetching: true,
        areParcelsReportsTracksInvalid: false,
        parcelsReportsTracks: null,
      };
    case Actions.REPORTS_EQUIPMENTS_TRACKS_REPORT_SUCCESS:
      return {
        ...state,
        equipmentsReportsTracks: payload,
        areEquipmentsReportsTracksFetching: false,
        areEquipmentsReportsTracksInvalid: false,
      };
    case Actions.REPORTS_PARCELS_TRACKS_REPORT_SUCCESS:
      return {
        ...state,
        parcelsReportsTracks: payload,
        areParcelsReportsTracksFetching: false,
        areParcelsReportsTracksInvalid: false,
      };
    case Actions.RESET_GEOJSON_TRACKS:
      return {
        ...state,
        parcelsReportsTracks: {},
        equipmentsReportsTracks: {},
        areParcelsReportsTracksFetching: false,
        areEquipmentsReportsTracksFetching: false,
      };
    case Actions.MODIFY_EQUIPMENTS_REPORTS_PREFERRED_DIMENSIONS:
      return {
        ...state,
        equipmentsPreferredDimension: payload,
      };
    case Actions.MODIFY_PARCELS_REPORTS_PREFERRED_DIMENSIONS:
      return {
        ...state,
        parcelsPreferredDimension: payload,
      };
    case Actions.REPORTS_OBSERVATIONS_SUCCESS: {
      const observationByID = {},
        observationIDs = [];
      (payload || []).forEach((obs) => {
        observationIDs.push(obs.id);
        let obsType = OBSERVATION_CONSTANTS.TYPE.EQUIPMENT;
        if (obs.parcel_id) obsType = OBSERVATION_CONSTANTS.TYPE.PARCEL;
        if (obs.device_session_id) obsType = OBSERVATION_CONSTANTS.TYPE.SESSION;
        observationByID[obs.id] = { ...obs, type: obsType };
      });
      return {
        ...state,
        observationIDs,
        observationByID,
        observationsFetching: false,
      };
    }
    case Actions.OBSERVATIONS_DELETE:
      return {
        ...state,
        observationIDs: state.observationIDs.filter((o) => o !== payload.observation.id),
        observationByID: omit(state.observationByID, payload.observation.id),
      };
    case Actions.OBSERVATIONS_DELETED_SUCCESS:
      return {
        ...state,
        observationIDs: state.observationIDs.filter((id) => !payload.includes(id)),
        observationByID: omit(state.observationByID, payload),
      };
    case Actions.OBSERVATIONS_ARCHIVED_SUCCESS: {
      const archivedObservations = { ...state.observationByID };
      (payload || []).forEach((archivedObservationID) => {
        const currentObservation = archivedObservations[archivedObservationID];
        archivedObservations[archivedObservationID] = {
          ...currentObservation,
          status: OBSERVATION_CONSTANTS.STATUS.ARCHIVED,
        };
      });
      return {
        ...state,
        observationByID: archivedObservations,
      };
    }
    case Actions.OBSERVATIONS_RESTORED_SUCCESS: {
      const restoredObservations = { ...state.observationByID };
      (payload || []).forEach((restoredObservationID) => {
        const currentObservation = restoredObservations[restoredObservationID];
        restoredObservations[restoredObservationID] = {
          ...currentObservation,
          status: OBSERVATION_CONSTANTS.STATUS.AVAILABLE,
        };
      });
      return {
        ...state,
        observationByID: restoredObservations,
      };
    }
    case Actions.REPORTS_REQUEST_OBSERVATIONS:
      return { ...state, observationsFetching: true };
    case Actions.WORKSITES_SUCCESS:
      return {
        ...state,
        worksites: payload,
      };
    case Actions.REPORTS_EQUIPMENTS_REPORT_TRACKS_FAIL:
      return {
        ...state,
        areEquipmentsReportsTracksInvalid: true,
        areEquipmentsReportsTracksFetching: false,
      };
    case Actions.REPORTS_PARCELS_REPORT_TRACKS_FAIL:
      return {
        ...state,
        areParcelsReportsTracksInvalid: true,
        areParcelsReportsTracksFetching: false,
      };
    case Actions.SET_WORKSITE_REPORT_BY_CLUSTER_INITIAL_CLUSTER:
      return {
        ...state,
        worksiteByClusterInitialCluster: payload,
      };
    case Actions.SET_WORKSITE_REPORT_INITIAL_EQUIPMENT_MODEL:
      return {
        ...state,
        worksiteInitialEquipmentModel: payload,
      };
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reports;
