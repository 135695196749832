import { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { UIHooks } from '@karnott/hooks';
import { fetchBeacons } from '../actions/beacons';
import { fetchClusters } from '../actions/clusters';
import { fetchCrops } from '../actions/crops';
import { fetchDevices } from '../actions/devices';
import { fetchEquipmentModels, fetchEquipments } from '../actions/equipments';
import { setDefaultTagFilters } from '../actions/filters';
import { fetchObservationCategories, fetchObservations } from '../actions/observations';
import { fetchParcels } from '../actions/parcels';
import { fetchAllMixtures } from '../actions/phyto';
import { fetchTags } from '../actions/tags';
import { setInitialTags } from '../actions/ui';
import { fetchUsers, logoutUser, retrieveMe } from '../actions/user';
import GoToMobileApp from '../components/GoToMobileApp';
import Header from '../components/Header';
import HelpDemoModal from '../components/HelpDemoModal';
import LeaveIE from '../components/LeaveIE';
import { OAUTH_URL } from '../constants';
import { PluginsContext } from '../contexts/plugins';
import { UIContext } from '../contexts/ui';
import Routes from '../routes';
import { color } from '../ui/theme';
import { isMobile } from '../utils';

const Wrapper = styled.div`
  padding-top: 55px;
  background-color: ${color('snow', 'light')};
`;

Modal.setAppElement('body');

function AppContainer({
  user,
  location,
  title,
  fetchParcels,
  fetchCrops,
  fetchEquipmentModels,
  fetchEquipments,
  fetchObservations,
  fetchObservationCategories,
  fetchClusters,
  fetchDevices,
  fetchBeacons,
  fetchUsers,
  fetchTags,
  fetchMixtures,
  retrieveMe,
  logoutUser,
  setDefaultTagFilters,
  setParcelTagsFilter,
}) {
  const { hasOverallCropManagementPlugin, hasOverallObservationPlugin } = useContext(PluginsContext);
  const { demoAccount } = useContext(UIContext);

  const leaveIE = Boolean(
    navigator.appName === 'Microsoft Internet Explorer' ||
      navigator.userAgent.match(/Trident/) ||
      navigator.userAgent.match(/rv:11/) ||
      navigator.userAgent.match(/MSIE/g),
  );
  const mobile = isMobile();
  const [isMobileOpen, , closeMobile] = UIHooks.useOpenCloseState(mobile);

  const redirectToOAuth = useCallback(() => {
    localStorage.setItem('fromURL', location.pathname || '/');
    window.location.replace(OAUTH_URL);
  }, [location]);

  const hasValidToken = useMemo(() => {
    if (user?.token) {
      if (user?.expireDate) {
        const now = new Date();
        const almostExpired = new Date(user.expireDate);
        almostExpired.setSeconds(almostExpired.getSeconds() - 10);
        return now <= almostExpired;
      }
      return true;
    }
    return false;
  }, [user]);

  const loginSafety = useCallback(() => {
    if (!hasValidToken) {
      redirectToOAuth();
    }
  }, [hasValidToken, redirectToOAuth]);

  useEffect(() => {
    loginSafety();
  }, [loginSafety]);

  const withToken = useCallback(
    (call) => {
      if (hasValidToken) {
        call();
      }
    },
    [hasValidToken],
  );

  useEffect(() => {
    withToken(fetchParcels);
  }, [fetchParcels, withToken]);
  useEffect(() => {
    if (hasOverallCropManagementPlugin) {
      withToken(fetchCrops);
    }
  }, [fetchCrops, hasOverallCropManagementPlugin, withToken]);
  useEffect(() => {
    withToken(fetchEquipmentModels);
  }, [fetchEquipmentModels, withToken]);
  useEffect(() => {
    withToken(fetchEquipments);
  }, [fetchEquipments, withToken]);
  useEffect(() => {
    if (hasOverallObservationPlugin) {
      withToken(fetchObservations);
      withToken(fetchObservationCategories);
    }
  }, [fetchObservations, withToken, hasOverallObservationPlugin, fetchObservationCategories]);
  useEffect(() => {
    withToken(fetchClusters);
  }, [fetchClusters, withToken]);
  useEffect(() => {
    withToken(fetchDevices);
  }, [fetchDevices, withToken]);
  useEffect(() => {
    withToken(fetchBeacons);
  }, [fetchBeacons, withToken]);
  useEffect(() => {
    withToken(fetchUsers);
  }, [fetchUsers, withToken]);
  useEffect(() => {
    withToken(fetchTags);
  }, [fetchTags, withToken]);
  useEffect(() => {
    withToken(fetchMixtures);
  }, [fetchMixtures, withToken]);

  useEffect(() => {
    if (hasValidToken) {
      retrieveMe().then((retrievedUser) => {
        if (retrievedUser?.application_role !== 'USER') {
          alert(`pro.karnott.fr n'est accessible qu'aux comptes utilisateurs`);
          logoutUser();
        } else if (retrievedUser?.tag_ids_preference) {
          const tags = retrievedUser.tag_ids_preference;
          setDefaultTagFilters(tags);
          setParcelTagsFilter(tags?.parcel || []);
        }
      });
    }
  }, [retrieveMe, logoutUser, hasValidToken, setDefaultTagFilters, setParcelTagsFilter]);

  if (!hasValidToken) {
    return null;
  } else {
    return (
      <Wrapper id="app-element">
        <Helmet {...{ title }} />
        <Header {...{ location }} onLogout={logoutUser} />
        <Routes {...{ location, logout: logoutUser }} />
        <GoToMobileApp isOpen={isMobileOpen} onRequestClose={closeMobile} />
        {demoAccount ? <HelpDemoModal /> : null}
        <LeaveIE isOpen={leaveIE} />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  location: state.router.location,
  user: state.user,
  title: state.ui.title,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCrops: () => dispatch(fetchCrops()),
  fetchParcels: () => dispatch(fetchParcels()),
  fetchEquipments: () => dispatch(fetchEquipments()),
  fetchObservations: () => dispatch(fetchObservations('?status=AVAILABLE')),
  fetchObservationCategories: () => dispatch(fetchObservationCategories()),
  fetchEquipmentModels: () => dispatch(fetchEquipmentModels()),
  fetchClusters: () => dispatch(fetchClusters()),
  fetchDevices: () => dispatch(fetchDevices()),
  fetchBeacons: () => dispatch(fetchBeacons()),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchTags: () => dispatch(fetchTags()),
  fetchMixtures: () => dispatch(fetchAllMixtures()),
  retrieveMe: () => dispatch(retrieveMe()),
  logoutUser: () => dispatch(logoutUser()),
  setDefaultTagFilters: (idsByKey) => dispatch(setDefaultTagFilters(idsByKey)),
  setParcelTagsFilter: (tags) => dispatch(setInitialTags(tags)),
});

const ConnectedAppContainer = memo(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
export default ConnectedAppContainer;
