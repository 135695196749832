import { useCallback } from 'react';
import ReactModal from 'react-modal';
import ScrollArea from 'react-scrollbar';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { CloseIcon } from '@karnott/icons';
import { fontFamily, pixelSpacing } from '@karnott/theme';
import { family, size, spacing, ui } from '../ui/theme';
import IcInfo from './icons/IcInfo';

const CloseModalTrigger = styled.button`
  border: 0;
  position: absolute;
  top: ${(3 / 4) * spacing}px;
  right: ${pixelSpacing()};
  font-size: ${size('xl')};
  line-height: 1;
  cursor: pointer;
  outline: none;
  @media (max-width: 500px) {
    top: 0;
    padding: ${pixelSpacing('small')} ${pixelSpacing('small')};
  }
`;

const CloseModal = function ({ onClick, color }) {
  return (
    <CloseModalTrigger {...{ onClick }}>
      <CloseIcon color={color ? color : 'currentColor'} fill={color ? color : 'currentColor'} />
    </CloseModalTrigger>
  );
};

const ModalAlertWrapper = styled.div`
  padding: ${(props) => (props.type === 'notice' ? spacing / 2 : spacing)}px;
  background-color: ${(props) =>
    props.type === 'info' ? '#6dbbe2' : (props) => (props.type === 'notice' ? '#fff' : '#ff4a49')};
  color: ${(props) => (props.type === 'notice' ? colors('grey') : '#fff')};
  display: flex;
  align-items: center;

  svg {
    font-size: ${size('xl')};
    min-width: ${pixelSpacing('large')};
    margin-right: ${pixelSpacing('small')};
  }
`;

const getIcon = () => <IcInfo />;

export const ModalAlert = function ({ type, children }) {
  return (
    <ModalAlertWrapper {...{ type }}>
      {getIcon(type)}
      <div>{children}</div>
    </ModalAlertWrapper>
  );
};

export const ModalBody = styled.div`
  padding: ${pixelSpacing('regularge')};
  background-color: ${(props) => (props.bare ? 'transparent' : colors('grey', 100))};
  border-top: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ spaced }) => (spaced ? 'space-between' : 'flex-end')};
  padding: ${pixelSpacing('regularge')} ${pixelSpacing('regularge')} ${pixelSpacing('small')};
  background: ${(props) => (props.background ? props.background : 'none')};
  border-top: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};
  text-align: right;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  > * {
    margin-bottom: ${pixelSpacing()};
  }

  > * + * {
    margin-left: ${pixelSpacing()};
  }

  @media (max-width: 500px) {
    padding: ${pixelSpacing('small')} ${pixelSpacing('regularge')};
  }
`;

export const ModalTitle = styled.h3`
  background-color: ${(props) => (props.bare ? 'transparent' : props.backgroundColor || colors('grey', 100))};
  font-family: ${fontFamily()};
  padding: ${pixelSpacing()} ${pixelSpacing('regularge')};
  margin: 0;
  font-weight: bold;
  font-size: 19px;
  max-width: 100%;
  display: flex;
  align-items: center;
  color: ${({ color = colors('black') }) => color};
  @media (max-width: 500px) {
    padding: ${pixelSpacing('small')} ${1.5 * spacing}px;
  }
`;

export const ModalSubtitle = styled.div`
  font-family: ${family('body')};
  padding: ${pixelSpacing('large')};
  margin: 0;
  font-size: ${size('m')};
  padding: 0 ${pixelSpacing('large')} ${pixelSpacing()} ${pixelSpacing('large')};
`;

const ExtendedScrollArea = styled(ScrollArea)`
  max-height: calc(100vh - ${6 * spacing}px);
  overflow: ${(props) => (props.overflowVisibleOnScrollAreas ? 'visible' : 'hidden')};
  @media (orientation: landscape) and (max-width: 800px), (max-width: 700px) {
    max-height: 100vh;
  }
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const StepWrapper = styled.div`
  margin-bottom: ${(props) => (props.inline ? 0 : spacing)}px;
  span {
    svg {
      font-size: ${size('xxl')};
    }
  }
  svg {
    color: ${colors('orange', 'dark')};
  }
`;
const StepIndex = styled.span`
  font-weight: 700;
`;
const StepTitle = styled.div`
  font-weight: 700;
`;

export const WarningBanner = styled.div`
  width: 100%;
  padding: 8px 24px;
  background-color: ${({ severity }) => ui(severity) || colors('red', 500)};
  color: ${colors('white')};
`;

export const ModalLink = styled.a`
  color: ${colors('orange', 'dark')};
  text-decoration: none;
`;

export const Step = function ({ index, content, title, contentIcon, subContent, subContentIcon }) {
  return (
    <StepWrapper>
      {index && (
        <StepIndex>
          {index}
          .&nbsp;
        </StepIndex>
      )}
      {title ? <StepTitle>{title}</StepTitle> : null}
      <span>
        {content} {contentIcon || null}
      </span>
      {subContentIcon && subContent ? (
        <div>
          {subContentIcon} {subContent}
        </div>
      ) : null}
    </StepWrapper>
  );
};

const Modal = function ({
  children,
  closeTimeoutMS,
  contentLabel,
  id,
  isOpen,
  noClose,
  onAfterOpen,
  onRequestClose,
  overflowVisibleOnScrollAreas,
  style,
  forwardRef,
  closeButtonId,
  keepOverflowVisible,
  closeButtonColor,
}) {
  const onRequestCloseCB = useCallback(
    (e) => {
      if (onRequestClose) {
        e && e.stopPropagation();
        onRequestClose();
      }
    },
    [onRequestClose],
  );
  return (
    <ReactModal
      {...{
        id,
        isOpen,
        onAfterOpen,
        onRequestClose,
        closeTimeoutMS,
        contentLabel,
        style,
      }}
      shouldCloseOnOverlayClick={false}
    >
      <ExtendedScrollArea
        ref={forwardRef}
        verticalScrollbarStyle={{ borderRadius: 200 }}
        contentStyle={{
          overflow: `${overflowVisibleOnScrollAreas ? 'visible' : 'hidden'}`,
        }}
        style={{
          overflow: `${overflowVisibleOnScrollAreas && keepOverflowVisible ? 'visible' : 'hidden'}`,
        }}
      >
        {!noClose ? <CloseModal onClick={onRequestCloseCB} id={closeButtonId} color={closeButtonColor} /> : null}
        {children}
      </ExtendedScrollArea>
    </ReactModal>
  );
};

export default Modal;
