export const PARCELS_CONSTANTS = {
  ERROR_CODES: {
    // see "error codes" section of api's documentation
    DATA_NOT_VALID: {
      code: 1101,
      title: 'ParcelsConstants.data_not_valid_title',
    },
    POLYGON_NOT_VALID: {
      code: 1102,
      title: 'ParcelsConstants.polygon_not_valid_title',
    },
    INTERSECTION: {
      code: 1103,
      title: 'ParcelsConstants.intersection_title',
    },
    SAVE_ERROR: {
      code: 1104,
      title: 'ParcelsConstants.save_error_title',
    },
    AREA_TOO_LARGE: {
      code: 1105,
      title: 'ParcelsConstants.area_too_large_title',
    },
    NOT_AUTHORIZED: {
      code: 1106,
      title: 'ParcelsConstants.not_authorized_title',
    },
  },
  CATEGORY: {
    ANONYMOUS: 'anonymous',
    FIELD: 'field',
    FARMHOUSE: 'farmhouse',
  },
  PARCEL_MARKER_CLUSTER: {
    THRESHOLD: 1000,
    ZOOM_LIMIT: 13,
  },
  PARCEL_NAMES: {
    ZOOM_LIMIT: 12,
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
    RENT_INACTIVE: 'INACTIVE',
    RENT_OVER: 'RENT_OVER',
    BEING_RENT: 'BEING_RENT',
    BEING_RENT_ACTIVE: 'BEING_RENT_ACTIVE',
  },
  PROJECTION_OPTIONS: [
    { value: 'LAMBERT93', label: 'LAMBERT93 (France)' },
    { value: 'LAMBERT93_CC44', label: 'LAMBERT93 / CC44 (France)' },
    { value: 'LAMBERT72', label: 'LAMBERT72 (Belgique)' },
    { value: 'WGS84', label: 'WGS84' },
    { value: '3857', label: 'WGS84 / Pseudo Mercator' },
    { value: '2056', label: 'CH1903 (Suisse)' },
    { value: '3416', label: 'ETRS89 (Austria Lambert)' },
    { value: '31287', label: 'MGI (Austria Lambert)' },
    { value: '32198', label: 'NAD83 (Canada/Quebec)' },
    { value: '4269', label: 'NAD83 (North America)' },
    { value: '25832', label: 'ETRS89 / UTM Zone 32N' },
  ],
  PARCEL_PAGE: {
    TAB: {
      SESSIONS: 'sessions',
      CONSUMPTIONS: 'consumptions',
      OBSERVATIONS: 'observations',
      HISTORY: 'history',
      CROPS: 'crops',
    },
  },
} as const;

export const PARCEL_COLOR_ID = {
  DEFAULT: 'null',
  CROPS: 'crops',
  TAGS: 'tags',
} as const;
