import { useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { colors } from '@karnott/colors';
import { DriverIcon } from '@karnott/icons';
import { LOCALE_FORMAT } from '../../../../constants';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { UIContext } from '../../../../contexts/ui';
import { I18n } from '../../../../i18n';
import { ANALYTICS, sendAnalyticsTooltipClickEvent } from '../../../../utils/AnalyticsConstants';
import { getEquipmentIcon } from '../../../../utils/equipments';
import { useDrawMarkerOnMap } from '../../effects';
import { openWorksiteTooltip } from './events';

export function useDrawWorksitesOnMap(map, worksite, events, hideWorksiteTooltip) {
  const { focusedWorksiteId } = useContext(UIContext);
  const { t } = useContext(I18nContext);
  const history = useHistory();
  const isWorksiteActive = useMemo(() => worksite.device_session_status === 'active', [worksite]);
  const [eqLatitude, eqLongitude] = useMemo(
    () => [worksite.equipmentLatitude, worksite.equipmentLongitude],
    [worksite.equipmentLatitude, worksite.equipmentLongitude],
  );
  // Worksite can be focus by WorksitesDatas(Dashboard)
  const focused = useMemo(() => {
    return (
      focusedWorksiteId &&
      (focusedWorksiteId === worksite.device_session_id ||
        worksite.equipment_instances.find((ei) => ei.id === focusedWorksiteId))
    );
  }, [focusedWorksiteId, worksite]);

  const { location, Icons, iconColor, backgroundColor } = useMemo(() => {
    let Icons = [];
    (worksite?.equipment_instances || []).forEach((wei) => {
      Icons.push(wei?.equipmentModel ? getEquipmentIcon(wei.equipmentModel.type) : getEquipmentIcon('misc'));
    });
    worksite?.related_user_sessions && worksite.related_user_sessions.forEach(() => Icons.push(DriverIcon));
    return {
      location: { latitude: eqLatitude, longitude: eqLongitude },
      Icons: Icons,
      iconColor: colors('white'),
      backgroundColor: focused ? colors('black', 'dark') : isWorksiteActive ? colors('green') : colors('grey', 'light'),
    };
  }, [eqLatitude, eqLongitude, focused, isWorksiteActive, worksite]);
  const marker = useDrawMarkerOnMap(
    map,
    worksite,
    location,
    Icons,
    iconColor,
    backgroundColor,
    events,
    undefined,
    undefined,
  );
  const openedTooltipLayer = useRef(null);
  const labels = useWorksiteTooltipLabels();
  const worksiteProps = useMemo(() => worksiteTooltipData(worksite, history), [history, worksite]);

  useEffect(() => {
    const { layerGroup, iconMarker, backgroundMarker } = marker;
    iconMarker?.setZIndexOffset(9000);
    backgroundMarker?.setZIndexOffset(9000);

    if (
      !focusedWorksiteId ||
      (focusedWorksiteId !== worksite.device_session_id &&
        !worksite.equipment_instances?.find((ei) => ei.id === focusedWorksiteId)) ||
      !layerGroup ||
      !iconMarker ||
      !backgroundMarker
    )
      return () => {};

    openedTooltipLayer.current = marker;
    !hideWorksiteTooltip && queueMicrotask(() => openWorksiteTooltip(worksiteProps, iconMarker, labels));
    iconMarker.setZIndexOffset(10000);
    backgroundMarker.setZIndexOffset(10000);

    return () => {
      if (openedTooltipLayer.current) {
        openedTooltipLayer.current.iconMarker.closeTooltip();
        iconMarker.setZIndexOffset(9000);
        backgroundMarker.setZIndexOffset(9000);
        openedTooltipLayer.current = null;
      }
    };
  }, [focusedWorksiteId, labels, marker, openedTooltipLayer, Icons, t, worksiteProps, hideWorksiteTooltip, worksite]);
}

export function useWorksiteTooltipLabels() {
  const { t } = useContext(I18nContext);
  return useMemo(
    () => ({
      in_progress: t('Commons.in_progress'),
      from_date: t('Commons.start'),
      last_update: t('Dashboard.last_actualization'),
    }),
    [t],
  );
}

export function worksiteTooltipData(worksite = {}, history) {
  let Icons = [],
    actions = [];
  (worksite.equipment_instances || []).forEach((wei) => {
    const actionCB = () => {
      sendAnalyticsTooltipClickEvent(window)(ANALYTICS.LABEL.TOOLTIPS.WORKSITE);
      history.push(`/equipments/${wei.id}/`);
    };
    const eqIcon = wei?.equipmentModel ? getEquipmentIcon(wei?.equipmentModel.type) : getEquipmentIcon('misc');
    const action = { name: wei?.label || '-', actionCB: actionCB };
    Icons.push(eqIcon);
    actions.push(action);
  });
  if (worksite.related_user_sessions) {
    worksite.related_user_sessions.forEach((user) => {
      Icons.push(DriverIcon);
      actions.push({
        icon: DriverIcon,
        name: user.user_fullname || user.user_email,
        actionCB: () => {
          history.push(`/drivers/${user.user_id}`);
        },
      });
    });
  }
  return {
    Icons: Icons,
    iconLength: Icons.length,
    worksiteType: I18n.t(`WorkType.${worksite.equipment_model}`),
    fromDate: moment(worksite.from_date).format(LOCALE_FORMAT),
    isInProgress: worksite.device_session_status === 'active',
    lastUpdate: moment(worksite.to_date).format(LOCALE_FORMAT),
    actions: actions,
    history,
  };
}
