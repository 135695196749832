import Icon from 'react-icon-base';

const IcInfo = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 14.75a1 1 0 0 1-2 0v-5a1 1 0 0 1 2 0zm-1-7.5A1.25 1.25 0 1 1 13.25 8 1.25 1.25 0 0 1 12 9.25z" />
    </g>
  </Icon>
);

export default IcInfo;
