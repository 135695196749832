import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { loadState } from '../api/localStorage';
import combinedReducer from './index';

export const history = createBrowserHistory();
const persistedState = loadState();

const store = createStore(
  combinedReducer(history),
  persistedState,
  compose(
    applyMiddleware(routerMiddleware(history), thunkMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);

export default store;
