import { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { OBSERVATION_CONSTANTS } from '@karnott/constants';
import { DriverIcon, EyeIcon, PhotoIcon, TimeIcon } from '@karnott/icons';
import { Tag } from '@karnott/tags';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

const ObservationTooltipWrapper = styled.div`
  font-style: ${({ archived }) => (archived ? 'italic' : 'normal')};
  color: ${({ archived }) => (archived ? colors('grey', 'dark') : colors('black', 'dark'))};
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-family: ${fontFamily()};
`;

const ObservationTooltipRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;
  > div {
    flex-shrink: 0;
  }
`;

const ObservationTooltipRowHeader = styled(ObservationTooltipRow)`
  gap: ${pixelSpacing('small')};
`;

const ObservationTooltipRowSmallIcon = styled(ObservationTooltipRow)`
  padding-left: 1px;
  gap: 6px;
`;

const Content = styled.div`
  margin-top: 5px;
  margin-left: ${pixelSpacing('small')};
  font-size: 13px;
`;

const ObservationTooltipHeaderLabel = styled.div`
  font-weight: 700;
  font-size: ${pixelSize('large')};
`;

const ObservationTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors('grey', 100)};
  padding: 5px 10px;
`;

const ObservationTooltipRowAction = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  height: 24px;
`;

export function ObservationPopup({
  occurredAt,
  updatedAt,
  Icon,
  content,
  author,
  chipAction,
  categoryLabel,
  categoryColor,
  filesLength,
  name,
  status,
  iconSize,
  labels,
}) {
  const contentArray = useMemo(() => {
    let splitContent = content;
    if (content.length > 100) {
      splitContent = content.slice(0, 100) + '...';
    }
    return splitContent;
  }, [content]);

  const isArchived = useMemo(() => status === OBSERVATION_CONSTANTS?.STATUS?.ARCHIVED, [status]);
  const iconCategoryColor = useMemo(() => (isArchived ? colors('grey') : categoryColor), [categoryColor, isArchived]);

  return (
    <ObservationTooltipWrapper archived={isArchived}>
      <ObservationTooltipRowHeader>
        <EyeIcon circled backgroundColor={iconCategoryColor} color={colors('white')} size={40} />
        <div>
          <ObservationTooltipHeaderLabel archived={isArchived}>{categoryLabel || '-'}</ObservationTooltipHeaderLabel>
          {isArchived && <Tag label={labels.archived.toLocaleUpperCase()} />}
        </div>
      </ObservationTooltipRowHeader>
      <Content>
        <ObservationTooltipRow>
          <Icon size={iconSize || 16} color={isArchived ? colors('grey', 'dark') : colors('black')} />
          <span>{name}</span>
        </ObservationTooltipRow>

        <ObservationTooltipRow>
          <DriverIcon size={16} color={isArchived ? colors('grey', 'dark') : colors('black')} />
          <span>{author}</span>
        </ObservationTooltipRow>

        <ObservationTooltipRowSmallIcon>
          <TimeIcon size={14} color={isArchived ? colors('grey', 'dark') : colors('black')} />
          <span>{updatedAt ? `${labels.last_update} : ${updatedAt}` : occurredAt}</span>
        </ObservationTooltipRowSmallIcon>

        {filesLength > 0 && (
          <ObservationTooltipRowSmallIcon>
            <PhotoIcon size={14} color={isArchived ? colors('grey', 'dark') : colors('black')} />
            <span>{filesLength}</span>
          </ObservationTooltipRowSmallIcon>
        )}

        {contentArray && <ObservationTooltipContent>{contentArray}</ObservationTooltipContent>}
      </Content>
      <ObservationTooltipRowAction>
        <Button primary thin title={labels.see} onClick={chipAction} rounded>
          {labels.see}
        </Button>
      </ObservationTooltipRowAction>
    </ObservationTooltipWrapper>
  );
}
