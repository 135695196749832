import { Actions } from '../actions';

const initialState = {
  byId: {},
  queue: [],
};

const snacks = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.SNACK_QUEUE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        queue: [...state.queue, payload.id],
      };
    case Actions.SNACK_POP_IMPORTANT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        queue: [payload.id, ...state.queue],
      };
    case Actions.SNACK_DISMISS:
      return {
        ...state,
        queue: state.queue.filter((id) => id !== payload),
      };
    case Actions.SNACK_CLEAR_ALL:
      return {
        ...state,
        queue: [],
      };
    default:
      return state;
  }
};

export default snacks;
