import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { crop } from '../api/schema';
import { I18n } from '../i18n';
import { normalize } from '../utils';
import { fetchParcel, fetchParcels } from './parcels';
import { sendSnack } from './snacks';

const receiveCrops = (payload) => ({
  type: Actions.CROPS_SUCCESS,
  payload,
});

export const fetchCrops = () => (dispatch) =>
  Api.getCrops().then(
    (result) => dispatch(receiveCrops(normalize(result, [crop]))),
    (e) => errorHasOccurred(e, dispatch),
  );

const parcelCropsHistorySuccess = (parcelID, payload) => ({
  type: Actions.PARCEL_CROPS_HISTORY_SUCCESS,
  payload: {
    parcelID,
    history: payload,
  },
});

const multiParcelCropsHistorySuccess = (parcelIDs) => ({
  type: Actions.MULTI_PARCEL_CROPS_HISTORY_SUCCESS,
  payload: {
    parcelIDs,
  },
});

export const linkParcelWithCrop = (id, payload) => (dispatch) =>
  Api.linkParcelWithCrop(id, payload).then(
    (result) => {
      dispatch(parcelCropsHistorySuccess(id, result));
      dispatch(fetchParcel(id));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const linkMultiParcelWithCrop = (ids, payload) => (dispatch) =>
  Api.linkMultiParcelWithCrop(ids, payload).then(
    () => {
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: I18n.t('Crop.link_ok'),
          action: 'OK',
        }),
      );
      dispatch(multiParcelCropsHistorySuccess(ids));
      dispatch(fetchParcels());
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const fetchParcelCropHistory = (id) => (dispatch) =>
  Api.getParcelCropHistory(id).then(
    (result) => dispatch(parcelCropsHistorySuccess(id, result)),
    (e) => errorHasOccurred(e, dispatch),
  );

export const deleteParcelCrop = (parcelID, parcelCropRangeID) => (dispatch) =>
  Api.deleteParcelCropHistory(parcelID, parcelCropRangeID).then(
    (result) => {
      dispatch(parcelCropsHistorySuccess(parcelID, result));
      dispatch(fetchParcel(parcelID));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const cropCreationSuccess = (payload) => ({
  type: Actions.CROP_CREATION_SUCCESS,
  payload,
});

export const createCrop = (payload) => (dispatch) =>
  Api.createCrop(payload).then(
    (result) => {
      dispatch(cropCreationSuccess(normalize([result], [crop])));
      return result;
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const updateParcelCrop = (parcelID, parcelCropRangeID, payload) => (dispatch) =>
  Api.updateParcelCropHistory(parcelID, parcelCropRangeID, payload).then(
    (result) => {
      dispatch(parcelCropsHistorySuccess(parcelID, result));
      dispatch(fetchParcel(parcelID));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const cropUpdated = (payload) => ({
  type: Actions.CROP_UPDATED,
  payload,
});

export const updateCrop = (cropID, payload) => (dispatch) =>
  Api.updateCrop(cropID, payload).then(
    (result) => {
      dispatch(cropUpdated(result));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const cropDeleted = (payload) => ({
  type: Actions.CROP_DELETED,
  payload,
});

export const deleteCrop = (cropID) => (dispatch) =>
  Api.deleteCrop(cropID).then(
    () => {
      dispatch(cropDeleted(cropID));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
