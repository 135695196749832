import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { receiveLogin } from '../actions/user';
import { history } from '../reducers/store';

function AccessTokenContainer({ receiveLogin, user }) {
  const location = useLocation();

  useEffect(() => {
    if (location?.hash === '' || location?.hash === undefined) {
      history.push('/');
    }
    const hashValues = (location?.hash || '').replace('#', '').split('&');
    let accessToken = 0,
      expireDate = null;

    for (let v of hashValues) {
      let keyValue = v.split('=');
      if (keyValue[0] === 'access_token') {
        accessToken = keyValue[1];
      }
      if (keyValue[0] === 'expires_in') {
        expireDate = new Date();
        expireDate.setSeconds(expireDate.getSeconds() + +keyValue[1]);
      }
    }
    receiveLogin({
      token: accessToken,
      expireDate: expireDate,
    });

    const fromURL = localStorage.getItem('fromURL');
    if (fromURL && fromURL !== '/') {
      localStorage.removeItem('fromURL');
      window.location.replace(decodeURIComponent(fromURL));
    }
  }, [location, receiveLogin]);

  useEffect(() => {
    if (user?.isAuthenticated) {
      history.push('/');
    }
  }, [user]);

  return null;
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  receiveLogin: (payload) => dispatch(receiveLogin(payload)),
});

const ConnectedAccessTokenContainer = connect(mapStateToProps, mapDispatchToProps)(AccessTokenContainer);
export default ConnectedAccessTokenContainer;
