import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import beacons from './beacons';
import clusters from './clusters';
import crops from './crops';
import devices from './devices';
import equipments from './equipments';
import filters from './filters';
import observations from './observations';
import parcelColors from './parcelColors';
import parcels from './parcels';
import partner from './partner';
import phyto from './phyto';
import reports from './reports';
import sessions from './sessions';
import snacks from './snacks';
import tags from './tags';
import tasks from './tasks';
import ui from './ui';
import unitLocations from './unitLocations';
import user from './user';
import worksites from './worksites';

const history = (history) => {
  const router = connectRouter(history);
  return combineReducers({
    beacons,
    clusters,
    crops,
    devices,
    equipments,
    filters,
    form,
    parcelColors,
    parcels,
    reports,
    router,
    sessions,
    partner,
    phyto,
    snacks,
    tasks,
    tags,
    ui,
    user,
    observations,
    unitLocations: unitLocations,
    worksites,
  });
};
export default history;
