import * as Api from '../api/api';
import { beacon } from '../api/schema';
import { I18n } from '../i18n';
import { normalize } from '../utils';
import { Actions, errorHasOccurred } from './index';
import { sendSnack } from './snacks';

const receiveBeacon = (payload) => ({
  type: Actions.BEACON_SUCCESS,
  payload,
});

const receiveBeacons = (payload) => ({
  type: Actions.BEACONS_SUCCESS,
  payload,
});

export const fetchBeacons = () => (dispatch) =>
  Api.getBeacons().then(
    (response) => dispatch(receiveBeacons(normalize(response, [beacon]))),
    (e) => errorHasOccurred(e, dispatch),
  );

export const fetchBeacon = (id) => (dispatch) =>
  Api.getBeacon(id).then(
    (response) => {
      dispatch(receiveBeacon(normalize([response], [beacon])));
      return response;
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const receiveBeaconEquipmentHistory = (id, history) => ({
  type: Actions.BEACON_EQUIPMENT_HISTORY_SUCCESS,
  payload: { id, history },
});

export const fetchBeaconEquipmentHistory = (id) => (dispatch) =>
  Api.getBeaconEquipmentHistory(id).then(
    (history) => dispatch(receiveBeaconEquipmentHistory(id, history)),
    (e) => errorHasOccurred(e, dispatch),
  );

const receiveBeaconDriverHistory = (id, history) => ({
  type: Actions.BEACON_DRIVER_HISTORY_SUCCESS,
  payload: { id, history },
});

export const fetchBeaconDriverHistory = (id) => (dispatch) =>
  Api.getBeaconDriverHistory(id).then(
    (history) => dispatch(receiveBeaconDriverHistory(id, history)),
    (e) => errorHasOccurred(e, dispatch),
  );

const beaconSuccessfullyLinked = (payload) => ({
  type: Actions.BEACON_SUCCESSFULLY_LINKED,
  payload,
});

const beaconSuccessfullyLinkedToDriver = (payload) => ({
  type: Actions.BEACON_SUCCESSFULLY_LINKED_TO_DRIVER,
  payload,
});

export const linkBeaconToEquipment = (beaconID, payload) => (dispatch) =>
  Api.linkBeaconToEquipment(beaconID, payload).then(
    (response) => {
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: I18n.t('Beacon.update_success'),
          action: 'OK',
        }),
      );
      dispatch(beaconSuccessfullyLinked({ beaconID, equipmentID: payload.equipment_id, history: response }));
      return response;
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const linkBeaconToDriver = (beaconID, payload) => (dispatch) =>
  Api.linkBeaconToDriver(beaconID, payload).then(
    (response) => {
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: I18n.t('Beacon.update_success'),
          action: 'OK',
        }),
      );
      dispatch(beaconSuccessfullyLinkedToDriver({ id: beaconID, driverID: payload.user_id, history: response }));
      return response;
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const beaconDriverSuccessfullyUnlinked = (payload) => ({
  type: Actions.BEACON_DRIVER_SUCCESSFULLY_UNLINKED,
  payload,
});

export const unlinkDriverBeacon = (beaconID) => (dispatch) => {
  Api.unlinkDriverBeacon(beaconID).then(
    (response) => {
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: I18n.t('Beacon.delete_success'),
          action: 'OK',
        }),
      );
      return dispatch(beaconDriverSuccessfullyUnlinked({ id: beaconID, history: response }));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const updateDriverBeaconEntry = (beaconUser) => (dispatch) => {
  Api.updateDriverBeaconEntry(beaconUser.id, beaconUser).then(
    () => {
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: I18n.t('Beacon.update_success'),
          action: 'OK',
        }),
      );
      // need to get device history because of possible merge
      return dispatch(fetchBeaconDriverHistory(beaconUser.beacon_uuid));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const deleteDriverBeaconEntry = (beaconUser) => (dispatch) =>
  Api.deleteDriverBeaconEntry(beaconUser.id).then(
    () => {
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: I18n.t('Beacon.delete_success'),
          action: 'OK',
        }),
      );
      // need to get device history because of possible merge
      return dispatch(fetchBeaconDriverHistory(beaconUser.beacon_uuid));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const removeDriverFromBeacon = (beaconUserID) => ({
  type: Actions.BEACON_DRIVER_REMOVE_DRIVER,
  payload: { id: beaconUserID },
});

// Tags

const linkedExistingTagToBeacon = (beaconId, tag) => ({
  type: Actions.BEACON_LINK_EXISTING_TAG,
  payload: { beaconId, tag },
});

const unlinkedTagFromBeacon = (beaconId, tagId) => ({
  type: Actions.BEACON_UNLINK_TAG,
  payload: { beaconId, tagId },
});

export const linkTagToBeacon = (beaconId, tag) => (dispatch) => {
  return Api.linkTagToBeacon(beaconId, tag.id)
    .then(() => dispatch(linkedExistingTagToBeacon(beaconId, tag)))
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const unlinkTagFromBeacon = (beaconId, tagId) => (dispatch) => {
  return Api.unlinkTagFromBeacon(beaconId, tagId)
    .then(() => dispatch(unlinkedTagFromBeacon(beaconId, tagId)))
    .catch((e) => errorHasOccurred(e, dispatch));
};
