import { Actions } from '.';
import { handleClusterIDURLParams, handleStatusURLParams } from '../utils/url';

export const toggleFilters = (entity) => ({
  type: Actions.TOGGLE_FILTERS,
  payload: { entity },
});

export const closeFilters = () => ({
  type: Actions.CLOSE_FILTERS,
});

export const modifyEquipmentFilters = (model) => ({
  type: Actions.MODIFY_EQUIPMENT_FILTERS,
  payload: model,
});

export const modifyParcelsFilters = (model) => ({
  type: Actions.MODIFY_PARCELS_FILTERS,
  payload: model,
});

export const modifyKarnottFilters = (model) => ({
  type: Actions.MODIFY_KARNOTT_FILTERS,
  payload: model,
});

export const modifyBeaconFilters = (model) => ({
  type: Actions.MODIFY_BEACON_FILTERS,
  payload: model,
});

export const modifyDriverBeaconFilters = (model) => ({
  type: Actions.MODIFY_DRIVER_BEACON_FILTERS,
  payload: model,
});

export const reInitEquipmentFilters = () => ({
  type: Actions.REINIT_EQUIPMENTS_FILTERS,
});

export const reInitParcelsFilters = () => ({
  type: Actions.REINIT_PARCELS_FILTERS,
});

export const reInitKarnottFilters = () => ({
  type: Actions.REINIT_KARNOTT_FILTERS,
});

export const reInitBeaconFilters = () => ({
  type: Actions.REINIT_BEACON_FILTERS,
});

export const reInitDriverBeaconFilters = () => ({
  type: Actions.REINIT_DRIVER_BEACON_FILTERS,
});

export const modifyDriverFilters = (model) => ({
  type: Actions.MODIFY_DRIVER_FILTERS,
  payload: model,
});
export const reInitDriverFilters = () => ({
  type: Actions.REINIT_DRIVERS_FILTERS,
});

export const selectAllCrops = () => ({
  type: Actions.SELECT_ALL_CROPS,
});

export const deselectAllCrops = () => ({
  type: Actions.DESELECT_ALL_CROPS,
});

export const selectAllClusters = () => ({
  type: Actions.SELECT_ALL_CLUSTERS,
});

export const deselectAllClusters = () => ({
  type: Actions.DESELECT_ALL_CLUSTERS,
});

export const setEquipmentSessionsFilters = (equipmentSessionFilters, withoutHandleClusterParams) => {
  if (!withoutHandleClusterParams) {
    handleClusterIDURLParams(equipmentSessionFilters);
    handleStatusURLParams(equipmentSessionFilters);
  }
  return {
    type: Actions.SET_EQUIPMENT_SESSIONS_FILTERS,
    payload: { equipmentSessionFilters },
  };
};

export const setDefaultTagFilters = (idsByKey) => ({
  type: Actions.SET_DEFAULT_TAG_FILTERS,
  payload: idsByKey,
});
