export const localStorageKeys = {
  ign: 'use-ign-tiles',
  state: 'state',
  drivers: 'drivers',
  equipments: 'equipments',
  parcels: 'parcels',
  tracks: 'tracks',
  observations: 'observations',
  parcelsName: 'parcelsName',
  colorizeFieldsBy: 'colorize-fields-by',
  forcePageRefresh: 'page-has-been-force-refreshed',
  showMarkerClusters: 'show-marker-clusters',
} as const;
