import findIndex from 'lodash-es/findIndex';
import omit from 'lodash-es/omit';
import { colors } from '@karnott/colors';
import { Actions } from '../actions';
import { CLUSTER_CONSTANTS } from '../constants/clusterConstants';
import { PARCEL_COLOR_ID } from '../constants/parcelsConstants';
import { I18n } from '../i18n';
import { isPluginActivated } from '../utils/clusters';
import { localStorageKeys } from '../utils/storage';

const initialState = {
  colors: [
    {
      title: I18n.t('Colors.by_crops'),
      checked: localStorage.getItem(localStorageKeys.colorizeFieldsBy) === PARCEL_COLOR_ID.CROPS,
      id: PARCEL_COLOR_ID.CROPS,
      catalog: {},
      items: [],
      disabled: false,
    },
    {
      title: I18n.t('Colors.by_tags'),
      checked: localStorage.getItem(localStorageKeys.colorizeFieldsBy) === PARCEL_COLOR_ID.TAGS,
      id: PARCEL_COLOR_ID.TAGS,
      catalog: {},
      items: [],
    },
  ],
};

function getTagColorItem(tagId) {
  return {
    id: tagId,
    labelKey: 'label',
  };
}

const parcelColors = (state = initialState, action) => {
  const { type, payload } = action;
  const cropColorsIndex = state.colors.findIndex((c) => c.id === PARCEL_COLOR_ID.CROPS);
  const tagsColorsIndex = state.colors.findIndex((c) => c.id === PARCEL_COLOR_ID.TAGS);
  const newColors = [...state.colors];

  switch (type) {
    case Actions.PARCELS_SUCCESS: {
      const cropIds = [];
      Object.values(payload.entities.parcel || {}).forEach((parcel) => {
        if (parcel?.current_crop_id && cropIds.indexOf(parcel.current_crop_id) === -1) {
          cropIds.push(parcel.current_crop_id);
        }
      });
      const newCropColors = cropIds.map((cropId) => ({
        labelKey: 'name',
        id: cropId,
      }));
      newCropColors.push({
        value: colors('black'),
        label: I18n.t('Crop.undefined'),
        id: 0,
      });
      newColors[cropColorsIndex].items = newCropColors;

      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CROP_CREATION_SUCCESS: {
      const newCrop = Object.values(payload.entities.crop)[0];
      newColors[cropColorsIndex].catalog = {
        ...newColors[cropColorsIndex].catalog,
        ...payload.entities.crop,
      };
      newColors[cropColorsIndex].items = [
        ...newColors[cropColorsIndex].items,
        {
          label: newCrop.name,
          id: newCrop.id,
        },
      ];
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CROPS_SUCCESS:
      newColors[cropColorsIndex].catalog = {
        ...newColors[cropColorsIndex].catalog,
        ...payload.entities.crop,
      };
      return {
        ...state,
        colors: newColors,
      };
    case Actions.COLORIZE_PARCEL_BY: {
      const colorId = payload;
      newColors.forEach((c) => {
        const isChecked = c.id === colorId && c.checked === false;
        c.checked = isChecked;
      });
      localStorage.setItem(localStorageKeys.colorizeFieldsBy, colorId);
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CROP_UPDATED: {
      const updatedCrop = payload;
      const updatedCropForCatalog = {
        [payload.id]: payload,
      };
      newColors[cropColorsIndex].catalog = {
        ...newColors[cropColorsIndex].catalog,
        ...updatedCropForCatalog,
      };
      const indexOfUpdatedCrop = findIndex(newColors[cropColorsIndex].items, (item) => item.id === updatedCrop.id);
      if (indexOfUpdatedCrop !== -1) {
        newColors[cropColorsIndex].items[indexOfUpdatedCrop] = {
          ...payload,
          labelKey: 'name',
        };
      }
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CROP_DELETED:
      newColors[cropColorsIndex].catalog = omit({ ...newColors[cropColorsIndex].catalog }, payload);
      newColors[cropColorsIndex].items = [...newColors[cropColorsIndex].items].filter((item) => item.id !== payload);
      return {
        ...state,
        colors: newColors,
      };
    case Actions.GET_TAGS: {
      const entries = payload.tags.map((t) => [t.id, t]);
      newColors[tagsColorsIndex].catalog = {
        ...newColors[tagsColorsIndex].catalog,
        ...Object.fromEntries(entries),
      };
      newColors[tagsColorsIndex].items = entries.map((t) => t[0]).map(getTagColorItem);
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.GET_TAG:
      newColors[tagsColorsIndex].catalog[payload.tag.id] = payload.tag;
      newColors[tagsColorsIndex].items = newColors[tagsColorsIndex].items
        .filter((i) => i.id !== payload.tag.id)
        .concat([getTagColorItem(payload.tag.id)]);
      return {
        ...state,
        colors: newColors,
      };
    case Actions.DELETE_TAG: {
      newColors[tagsColorsIndex].catalog = omit(newColors[tagsColorsIndex].catalog, payload.id);
      newColors[tagsColorsIndex].items = newColors[tagsColorsIndex].items.filter((i) => i.id !== payload.id);
      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.CLUSTERS_SUCCESS: {
      const viti = payload
        .filter((c) => c.role === 'ADMIN')
        .some((c) => c.activities?.length === 1 && c.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.viticulture);
      const cropPlugin = payload.some((c) => isPluginActivated(c, CLUSTER_CONSTANTS.PLUGINS_NAMES.crop_management));

      if (viti) {
        newColors[cropColorsIndex].title = I18n.t('Colors.by_grapes');
      }
      newColors[cropColorsIndex].disabled = !cropPlugin;

      return {
        ...state,
        colors: newColors,
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default parcelColors;
