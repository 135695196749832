import { colors } from '@karnott/colors';
import { color } from '../ui/theme';

export const DEVICE_CONSTANTS = {
  TYPE: {
    KARNOTT: 'Karnott',
    BEACON: 'Karnott Outil',
    DRIVER_BEACON: 'Karnott Chauffeur',
  },
  BEACON_TYPE: {
    EQUIPMENT: 'EQUIPMENT',
    DRIVER: 'DRIVER',
  },
  STATUS: {
    DELETED: 'DELETED',
    IN_USE: 'IN_USE',
    AVAILABLE: 'AVAILABLE',
  },
  HEALTH_STATUS: {
    UP: 'UP',
    DOWN: 'DOWN',
    CRITICAL: 'CRITICAL',
  },
  BATTERY: {
    COLOR: {
      CRITICAL_LEVEL_COLOR: colors('orange', 'dark'),
      LOW_LEVEL_COLOR: color('yellow'),
      HIGH_LEVEL_COLOR: colors('green'),
    },
    LEVEL: {
      LOW_STEP: 30,
      CRITICAL_STEP: 15,
    },
    HEALTH_STATUS: {
      OK: 'OK',
      WARNING: 'WARNING',
      CRITICAL: 'CRITICAL',
    },
  },
  CHARGING_STATE: {
    DISCHARGING: 'DISCHARGING',
    CHARGING: 'CHARGING',
    CHARGED: 'CHARGED',
  },
};
