import { startTransition, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { flexRender } from '@tanstack/react-table';
import { colors, rgbaColors } from '@karnott/colors';
import { ChevronIcon } from '@karnott/icons';
import { fontFamily, msDuration, pixelSize } from '@karnott/theme';
import { useTable, useTotalRow, useVirtualRows } from './hooks';

const TableContainer = styled.div`
  height: 100%;
  overflow: auto;
  border-radius: 7px;
  background-color: ${colors('white')};
`;

const TableElement = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-family: ${fontFamily('default')};
`;

const Thead = styled.thead`
  background: ${({ background }) =>
    background ? (background in colors._palette ? colors(background, 500) : background) : colors('grey', 200)};
  font-family: ${fontFamily('overstatement')};
  color: ${({ background }) => colors(background ? 'white' : 'grey')};
  text-transform: uppercase;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const HeaderCell = styled.td`
  font-weight: normal;
  font-size: ${pixelSize('small')};
  padding: 0 10px;
  min-width: 50px;

  ${({ canSort }) =>
    canSort
      ? css`
          :hover {
            color: ${({ background }) => (background ? 'inherit' : colors('grey', 600))};
            cursor: pointer;
          }
        `
      : null}

  :has(.cell_no_data) {
    min-width: 0;
  }
`;

const HeaderWithSort = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  :has(.cell_no_data) {
    min-width: 0;
    justify-content: center;
  }

  .cell_no_data label > div:last-of-type {
    display: none;
  }
`;

const HeaderTitle = styled.span`
  user-select: none;
  padding: 14px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :empty {
    display: none;
  }
`;

const SortButtons = styled.div`
  flex-shrink: 0;

  > div:first-of-type {
    rotate: 0.5turn;
    translate: 0 4px;
  }

  > div:last-of-type {
    translate: 0 -4px;
  }
`;

const Tbody = styled.tbody`
  background-color: ${colors('white')};
  color: ${colors('black')};
  font-size: ${pixelSize('regular')};

  tr:last-of-type td {
    border-bottom: none;
  }
`;

const TableRow = styled.tr`
  transition: background-color ${msDuration('short')} linear;

  .cell_on_hover {
    opacity: 0;
    transition: opacity ${msDuration('short')} linear;
  }

  :hover .cell_on_hover {
    opacity: 1;
  }

  ${({ highlightOnHover }) =>
    highlightOnHover
      ? css`
          :hover {
            background-color: ${colors('green', 200)};
            td {
              border-bottom-color: ${colors('green', 200)};
            }
          }

          :has(+ :hover) td {
            border-bottom-color: ${colors('green', 200)};
          }
        `
      : null}
`;

const BodyCell = styled.td`
  padding: 14px 10px;
  border-bottom: 1px solid ${colors('grey', 100)};
  transition: border-bottom-color ${msDuration('short')} linear;

  .cell_no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    label > div:last-of-type {
      display: none;
    }
  }
`;

const Tfoot = styled.tfoot`
  background: ${colors('black')};
  color: ${colors('white')};
  margin: 0;
  position: sticky;
  bottom: 0;
  font-size: ${pixelSize('regular')};

  th {
    font-weight: normal;
    text-align: left;
  }
`;

const FooterCell = styled.td`
  margin: 0;
  padding: 14px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  * {
    color: ${colors('white')} !important;
    font-family: ${fontFamily()} !important;
    font-weight: normal !important;
  }
`;

function ColumnHeader({ header, Icon, background }) {
  const canSort = header.column.getCanSort();
  const isSorted = header.column.getIsSorted();
  const headerTitle = useRef(null);
  const onClick = useCallback(
    (e) => {
      startTransition(() => header.column.getToggleSortingHandler()(e));
    },
    [header.column],
  );

  const iconColorFor = (order) => rgbaColors(background ? 'white' : 'grey', 500, isSorted === order ? 1 : 0.5);

  return (
    <HeaderCell
      title={headerTitle.current?.innerText ? headerTitle.current?.textContent : null}
      canSort={canSort}
      onClick={onClick}
      background={background}
    >
      <HeaderWithSort isSortedAsc={isSorted === 'asc'}>
        {header.index === 0 && Icon ? <Icon size={16} color="currentColor" /> : null}
        <HeaderTitle ref={headerTitle}>{flexRender(header.column.columnDef.header, header.getContext())}</HeaderTitle>
        {canSort ? (
          <SortButtons>
            <ChevronIcon size={18} color={iconColorFor('asc')} />
            <ChevronIcon size={18} color={iconColorFor('desc')} />
          </SortButtons>
        ) : null}
      </HeaderWithSort>
    </HeaderCell>
  );
}

function VirtualPadding({ size }) {
  return size > 0 ? (
    <tr>
      <td style={{ height: size }} />
    </tr>
  ) : null;
}

function Row({ tableRows, virtualRow, measureElement, highlightOnHover }) {
  const row = tableRows.at(virtualRow.index);
  return (
    <TableRow data-index={virtualRow.index} highlightOnHover={highlightOnHover} ref={measureElement}>
      {row.getVisibleCells().map((cell) => (
        <BodyCell key={cell.id} className={cell.column.columnDef.displayOnHover ? 'cell_on_hover' : ''}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </BodyCell>
      ))}
    </TableRow>
  );
}

export function Table({
  columns = [],
  rows = [],
  sortAll,
  displayTotal,
  onSelectionChange = () => {},
  Icon,
  headerColor,
  highlightOnHover = true,
}) {
  /* Table initialization */
  const { table, columnDefs } = useTable({ columns, rows, onSelectionChange, sortAll });
  const { rows: tableRows } = table.getRowModel();

  /* Virtualization */
  const { setScrollElement, virtualRows, paddingBottom, paddingTop, measureElement } = useVirtualRows({
    rows: tableRows,
  });

  /* Total footer cells */
  const totalCells = useTotalRow({ rows: tableRows, columnDefs, displayTotal });

  return (
    <TableContainer ref={setScrollElement}>
      <TableElement>
        <Thead background={headerColor}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <ColumnHeader key={header.id} header={header} Icon={Icon} background={headerColor} />
              ))}
            </tr>
          ))}
        </Thead>

        <Tbody>
          <VirtualPadding size={paddingTop} />

          {virtualRows.map((virtualRow) => (
            <Row
              key={virtualRow.key}
              tableRows={tableRows}
              virtualRow={virtualRow}
              measureElement={measureElement}
              highlightOnHover={highlightOnHover}
            />
          ))}

          <VirtualPadding size={paddingBottom} />
        </Tbody>

        {displayTotal ? (
          <Tfoot>
            <tr>
              <FooterCell as="th">Total</FooterCell>
              {totalCells.map((cell, i) => {
                const columnDef = table.getAllColumns().at(i + 1).columnDef;
                const renderFn = cell
                  ? columnDefs.find((col) => col.accessorKey === columnDef.accessorKey)?.cell
                  : null;
                return (
                  <FooterCell key={columnDef.id || columnDef.accessorKey}>
                    {renderFn?.({ getValue: () => cell })}
                  </FooterCell>
                );
              })}
            </tr>
          </Tfoot>
        ) : null}
      </TableElement>
    </TableContainer>
  );
}
