import removeAccents from 'remove-accents';
import { colors } from '@karnott/colors';
import { BatteryIcon, BeaconIcon, KarnottIcon } from '@karnott/icons';
import { DEVICE_CONSTANTS } from '../constants/deviceConstants';

export const deviceContains = (device, filter) =>
  removeAccents(device.name || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase()) || device.serialnumber.includes(filter);

export const beaconsContains = (beacon, filter) =>
  removeAccents(beacon.uuid || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase());

export const getBatteryLevel = (device) => {
  // if the equipmentType === 0, it means that the equipment only use the internal battery, and that its the level matters
  return device?.deviceModel !== undefined
    ? Math.max(0, Math.min(device.deviceModel === 0 ? device.batteryLevel : device.externalBatteryLevel, 100))
    : undefined;
};

export const getBatteryLevelIcon = (
  level = 0,
  isCharging,
  outlineColor = colors('black'),
  chargingColor = outlineColor,
) => {
  if (level > 100) {
    level = 100;
  }
  return (
    <BatteryIcon
      batteryLevel={level}
      batteryColor={getBatteryLevelColor(level)}
      charging={isCharging}
      chargingColor={chargingColor}
      color={level === 0 ? colors('red') : outlineColor}
      size={20}
    />
  );
};

const getBatteryLevelColor = (level) => {
  let color = DEVICE_CONSTANTS.BATTERY.COLOR.HIGH_LEVEL_COLOR;
  if (level <= DEVICE_CONSTANTS.BATTERY.LEVEL.LOW_STEP && level > DEVICE_CONSTANTS.BATTERY.LEVEL.CRITICAL_STEP) {
    color = DEVICE_CONSTANTS.BATTERY.COLOR.LOW_LEVEL_COLOR;
  } else if (level <= DEVICE_CONSTANTS.BATTERY.LEVEL.CRITICAL_STEP) {
    color = DEVICE_CONSTANTS.BATTERY.COLOR.CRITICAL_LEVEL_COLOR;
  }
  return color;
};

export const getDeviceStatusIcon = (type = DEVICE_CONSTANTS.TYPE.KARNOTT, status, size = 20, hasHealthIssue) => {
  if (type === DEVICE_CONSTANTS.TYPE.BEACON) {
    return (
      <BeaconIcon
        circled
        backgroundColor={status === DEVICE_CONSTANTS.STATUS.IN_USE ? colors('green') : colors('black')}
        color={colors('white')}
        {...{ size }}
      />
    );
  } else {
    switch (status) {
      case DEVICE_CONSTANTS.STATUS.IN_USE:
        return (
          <KarnottIcon
            circled
            backgroundColor={colors('green')}
            color={colors('white')}
            healthIssue={hasHealthIssue}
            size={size}
          />
        );
      default:
        return (
          <KarnottIcon
            circled
            backgroundColor={colors('black', 'dark')}
            color={colors('white')}
            healthIssue={hasHealthIssue}
            size={size}
          />
        );
    }
  }
};

export const getBatteryHealth = (device) => device?.health?.battery?.status;

export const hasCriticalHealth = (device) =>
  (device?.health?.status && device?.health?.status !== DEVICE_CONSTANTS.HEALTH_STATUS.UP) ||
  getBatteryHealth(device) === DEVICE_CONSTANTS.BATTERY.HEALTH_STATUS.CRITICAL;

export const getDeviceHealth = (device) => device?.health?.status;

export function isCharging(chargingState) {
  return (
    !!chargingState &&
    (chargingState === DEVICE_CONSTANTS.CHARGING_STATE.CHARGING ||
      chargingState === DEVICE_CONSTANTS.CHARGING_STATE.CHARGED)
  );
}
