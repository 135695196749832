/** @returns {string} A random hexadecimal color */
export function randomHexColor() {
  return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
}

/**
 * @param   {Partial<Tags>[] | undefined} partialList List of partial tags, with at least an id
 * @param   {Tags[]}                      allTags     Complete list of created tags available to the user
 *
 * @returns {Tags[]}                                  The given list of partial tags, with unknown tags removed and
 *   other tags completed
 */
export function getCompleteTags(partialList, allTags) {
  return (partialList || []).map((t1) => allTags.find((t2) => t1.id === t2.id)).filter(Boolean);
}
