import { flushSync } from 'react-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { popup as createPopup, tooltip as createTooltip } from 'leaflet';
import { colors } from '@karnott/colors';
import { PARCELS_CONSTANTS } from '../constants/parcelsConstants';
import { spacing } from '../ui/theme';

const defaultStyles = {
  strokeColor: '#2EE8EA',
  fillColor: '#1DD7D9',
  color: '#1DD7D9',
  fillOpacity: 0.2,
};

const focusedStyles = {
  strokeColor: '#2EE8EA',
  fillColor: '#2EE8EA',
  fillOpacity: 0.6,
};

const toDeleteStyles = {
  strokeColor: '#ffd641',
  fillColor: '#ffc900',
  fillOpacity: 0.2,
};

const errorStyles = {
  strokeColor: '#FF4949',
  fillColor: '#FF7777',
  fillOpacity: 0.2,
};

const focusedErrorStyles = {
  strokeColor: '#FF4949',
  fillColor: '#FF4949',
  fillOpacity: 0.6,
};

const anonymousStyles = {
  strokeColor: '#f67d00',
  color: '#f67d00',
  fillColor: '#f7931e',
  fillOpacity: 0.2,
  strokeWeight: 1,
  zIndex: 203,
};

const focusedAnonymousStyles = {
  strokeColor: '#f67d00',
  fillColor: '#f67d00',
  fillOpacity: 0.6,
  strokeWeight: 1,
  zIndex: 204,
};

const anonymousClickedStyles = {
  strokeColor: '#76c22c',
  fillColor: '#9bde72',
  fillOpacity: 0.2,
  strokeWeight: 1,
  zIndex: 205,
};

const farmHouseStyles = {
  strokeColor: '#D8C60A',
  fillColor: '#dbcb22',
  fillOpacity: 0.2,
};

export const hotlineStyles = (min, max) => ({
  max,
  min,
  palette:
    min > 0
      ? { 0: '#2DE8EA', 0.1: '#76C22C', 0.99: '#76C22C', 1: '#FF4949' }
      : { 0: '#76C22C', 0.99: '#76C22C', 1: '#FF4949' },

  weight: 3,
  smoothFactor: 1,
  outlineWidth: 0,
});

export const buildParcelStyle = ({ category, error, clusterId, focused, clicked }) => {
  let style = defaultStyles;
  if (!error && clusterId) {
    if (category === PARCELS_CONSTANTS.CATEGORY.FIELD) {
      if (focused) {
        style = focusedStyles;
      }
      if (clicked) {
        style = toDeleteStyles;
      }
    } else if (category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
      style = farmHouseStyles;
    }
  } else {
    if (error) {
      if (focused) {
        style = focusedErrorStyles;
      } else {
        style = errorStyles;
      }
    } else if (!clusterId) {
      if (clicked) {
        style = anonymousClickedStyles;
      } else {
        if (focused) {
          style = focusedAnonymousStyles;
        } else {
          style = anonymousStyles;
        }
      }
    }
  }
  return style;
};

function getStaticHTML(component) {
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(component);
  });
  return div.innerHTML;
}

export function openLeafletPopup(layer, content, popupOptions = {}, position) {
  const popup = createPopup(popupOptions);

  const html = getStaticHTML(content);

  popup.setContent(html);

  layer && layer.bindPopup(popup).openPopup(position);
  popup._contentNode && hydrateRoot(popup._contentNode, content);
}

export function openLeafletTooltip(layer, content, tooltipOptions = {}, position) {
  const tooltip = createTooltip(tooltipOptions);

  const html = getStaticHTML(content);

  tooltip.setContent(html);

  layer && layer.bindTooltip(tooltip).openTooltip(position);
}

export function getCustomMarkerPositionStyle(location) {
  if (!location) return [];
  return [
    {
      location: location,
      color: 'rgba(249, 227, 201, 0.4)',
      radius: spacing * 1.5,
      pane: 'shadowPane',
      className: 'place-location-marker three',
      renderer: 'svg',
    },
    {
      location: location,
      color: 'rgb(250, 144, 144, 0.8)',
      radius: spacing,
      pane: 'shadowPane',
      className: 'place-location-marker two',
      renderer: 'svg',
    },
    {
      location: location,
      color: colors('red', 'dark'),
      radius: spacing / 2,
      pane: 'shadowPane',
      renderer: 'svg',
    },
  ];
}

/**
 * Set a selection of features as inert (click-through). `filter` takes a Leaflet GeoJSON feature and returns `true` if
 * the feature should be inert. If `filter` is omitted, then all the features are set as inert.
 */
export function inertFeatures(filter) {
  return (feature) => ({ className: !filter || filter(feature) ? 'not-interactive' : '' });
}
