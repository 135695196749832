import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { I18n } from '../i18n';
import { randomHexColor } from '../utils/tags';
import { sendSnack } from './snacks';

const t = I18n.t;

const receiveTag = (tag) => ({
  type: Actions.GET_TAG,
  payload: { tag },
});

const receiveTags = (tags) => ({
  type: Actions.GET_TAGS,
  payload: { tags },
});

const deletedTag = (id) => ({
  type: Actions.DELETE_TAG,
  payload: { id },
});

export const fetchTags = () => (dispatch) => {
  return Api.getTags()
    .then((tags) => dispatch(receiveTags(tags)))
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const createTag = (label, clusterId, color) => (dispatch) => {
  return Api.createTag(label, color || randomHexColor(), clusterId)
    .then((tag) => {
      dispatch(receiveTag(tag));
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: t('Admin.create_tag_snack'),
          action: 'OK',
        }),
      );
      return tag;
    })
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const updateTag = (id, payload) => (dispatch) => {
  return Api.updateTag(id, payload)
    .then((tag) => {
      dispatch(receiveTag(tag));
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: t('Admin.edit_tag_snack'),
          action: 'OK',
        }),
      );
    })
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const deleteTag = (id) => (dispatch) => {
  return Api.deleteTag(id)
    .then(() => {
      dispatch(deletedTag(id));
      dispatch(
        sendSnack({
          type: 'success',
          duration: 3000,
          message: t('Admin.delete_tag_snack'),
          action: 'OK',
        }),
      );
    })
    .catch((e) => errorHasOccurred(e, dispatch));
};
