import Polyglot from 'node-polyglot';
import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import { loadMomentLocale } from './locale';
import nl from './nl.json';

const LOCALES = ['de', 'en', 'fr', 'nl'] as const;
export type Locale = (typeof LOCALES)[number];
const DEFAULT_LOCALE: Locale = 'en';

type LocaleDefinition = typeof fr;
type LocaleKey = keyof LocaleDefinition;

function isCorrectLocale(locale?: string): locale is Locale {
  return !!locale && LOCALES.includes(locale);
}

function getShortLocale(locale: string): Locale {
  const shortLocale = locale.toLowerCase().split(/[_-]+/)[0];
  if (isCorrectLocale(shortLocale)) {
    return shortLocale;
  }
  return DEFAULT_LOCALE;
}

const locale = getShortLocale((navigator.languages && navigator.languages[0]) || navigator.language);

loadMomentLocale(locale);

const locales: Record<Locale, Record<string, string>> = {
  de,
  en,
  fr,
  nl,
};

const phrases = locales[locale];

const polyglot = new Polyglot({
  locale,
  phrases,
  pluralRules: {
    pluralTypes: {
      treeTypesPlural: (n) => (n <= 1 ? n : 2),
    },
    pluralTypeToLanguages: {
      treeTypesPlural: ['de', 'en', 'fr', 'nl'],
    },
  },
});

export const I18n = {
  t: (polyglot.t.bind(polyglot) || (() => '')) as (
    key: LocaleKey,
    options?: number | Polyglot.InterpolationOptions,
  ) => string,
  locale,
};
