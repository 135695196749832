import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { elevation, fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

export const ExportWrapper = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: ${pixelSpacing()};
  }
`;

export const WorksitesReportsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WorksitesReportsTableStats = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${pixelSpacing()};
  background: rgb(35, 139, 133);
  background: linear-gradient(90deg, rgba(35, 139, 133, 1) 0%, rgba(118, 194, 44, 1) 100%);
`;

export const WorksitesReportsTableStatsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  > *:not(:last-child) {
    margin-right: ${pixelSpacing()};
  }
  margin-bottom: ${pixelSpacing()};
`;

export const WorksitesReportsTableStatsHeaderItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ selected }) => (selected ? colors('white') : 'transparent')};
  border-radius: ${pixelSpacing()};
  font-family: ${fontFamily()};
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  color: ${({ selected }) => (selected ? colors('green') : colors('white'))};
  font-size: ${pixelSize('small')};
  cursor: pointer;
  > *:not(:last-child) {
    margin-right: ${pixelSpacing('small')};
  }
  > span:first-of-type {
    text-transform: uppercase;
  }
  > span {
    padding: ${pixelSpacing('small')} 0;
  }
`;

export const WorksitesReportsTableStatsContent = styled.div`
  display: flex;
  align-items: stretch;
  > *:not(:last-child) {
    margin-right: ${pixelSpacing('small')};
  }
  @media (max-width: 1366px) {
    flex-direction: column;
    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: ${pixelSpacing('small')};
    }
  }
`;

export const WorksitesReportsCharts = styled.div`
  display: flex;
  flex: 4;
  > *:not(:only-child):last-child {
    margin-left: ${pixelSpacing('small')};
  }
`;

export const WorksitesReportsNumericalValues = styled.div`
  display: flex;
  flex: 1;
  > *:not(:only-child):last-child {
    margin-left: ${pixelSpacing('small')};
  }
`;

export const StatsContentBloc = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${({ weight }) => weight || 1};
  ${({ elevated }) => (elevated ? elevation({ elevated: true }) : null)}
  > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${colors('white')};
    border-radius: 4px;
    padding: ${pixelSpacing()};
  }
  > *:not(:only-child):last-child {
    margin-top: ${pixelSpacing('small')};
  }
`;

export const StatsTitle = styled.div`
  font-size: ${pixelSize('large')};
  font-family: ${fontFamily()};
  font-weight: 700;
  margin-bottom: ${pixelSpacing('small')};
`;

export const StatsSimpleNumericValue = styled.div`
  white-space: nowrap;
  font-size: 32px; //${pixelSize('xLarge')};
  font-family: ${fontFamily()};
  font-weight: 700;
`;

export const StatsCircularChart = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  max-width: 100%;
  overflow: hidden;
  padding: 2px;
  margin-top: ${({ withMarginTop }) => (withMarginTop ? pixelSpacing('small') : 0)};
  > * {
    min-height: 200px;
    max-height: 200px;
    box-shadow: none;
  }
`;
