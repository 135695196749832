import removeAccents from 'remove-accents';
import { FarmhouseIcon, ParcelRentIcon, PlotsIcon } from '@karnott/icons';
import { PARCELS_CONSTANTS, PARCEL_COLOR_ID } from '../constants/parcelsConstants';
import { I18n } from '../i18n';
import { color } from '../ui/theme';
import { formatArea } from './index';

export const parcelContains = (parcel = {}, filter = '') =>
  removeAccents(parcel.name || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase());

export const errorToDisplay = (errorCode) => {
  switch (errorCode) {
    case PARCELS_CONSTANTS.ERROR_CODES.DATA_NOT_VALID.code:
      return PARCELS_CONSTANTS.ERROR_CODES.DATA_NOT_VALID.title;
    case PARCELS_CONSTANTS.ERROR_CODES.POLYGON_NOT_VALID.code:
      return PARCELS_CONSTANTS.ERROR_CODES.POLYGON_NOT_VALID.title;
    case PARCELS_CONSTANTS.ERROR_CODES.INTERSECTION.code:
      return PARCELS_CONSTANTS.ERROR_CODES.INTERSECTION.title;
    case PARCELS_CONSTANTS.ERROR_CODES.SAVE_ERROR.code:
      return PARCELS_CONSTANTS.ERROR_CODES.SAVE_ERROR.title;
    case PARCELS_CONSTANTS.ERROR_CODES.AREA_TOO_LARGE.code:
      return PARCELS_CONSTANTS.ERROR_CODES.AREA_TOO_LARGE.title;
    default:
      return PARCELS_CONSTANTS.ERROR_CODES.SAVE_ERROR.title;
  }
};

export const getParcelIcon = (parcel, props = {}) => {
  if (!parcel) {
    return null;
  }
  if (parcel.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
    return <FarmhouseIcon circled size={props.size} color="white" backgroundColor={color('yellow')} />;
  }

  if (parcel.status === PARCELS_CONSTANTS.STATUS.BEING_RENT) {
    return <ParcelRentIcon size={props.size} color="white" backgroundColor={color('grey', 'light')} circled />;
  }

  let backgroundColor = 'cyan';
  let backgroundVariation = 'default';
  if (parcel.status === PARCELS_CONSTANTS.STATUS.RENT_OVER) {
    backgroundColor = 'grey';
  }
  if (parcel.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE) {
    backgroundColor = 'sepia';
    backgroundVariation = 'light';
  }
  if (parcel.cluster_id === 0) {
    backgroundColor = 'orange';
  }
  return (
    <PlotsIcon circled size={props.size} color="white" backgroundColor={color(backgroundColor, backgroundVariation)} />
  );
};

export const buildCropLabel = (crop, unit) => {
  let label = crop.name;
  label += crop.code ? ` (${crop.code}) ` : ' ';
  if (crop.parcel_count) {
    label += `(${crop.parcel_count} ${crop.parcel_count > 1 ? I18n.t('Filters.parcels') : I18n.t('Filters.parcel')}`;
    label += ' - ';
    label += `${formatArea(crop.parcel_area_sum, unit, true)})`;
  } else {
    label += `(0 ${I18n.t('Filters.parcel')})`;
  }
  return label;
};

export const buildTagLabel = (tag) => {
  return tag.label;
};

export const colorLabelBuilderById = {
  [PARCEL_COLOR_ID.CROPS]: buildCropLabel,
  [PARCEL_COLOR_ID.TAGS]: buildTagLabel,
};

export const parcelHasCropColor = (parcelCropId, colorItem) => {
  return colorItem?.id === 0 ? !parcelCropId : parcelCropId === colorItem?.id;
};

export const parcelHasTagColor = (tagId, colorItem) => {
  return tagId === colorItem?.id;
};

export function determineFormat(fileExtension) {
  switch (fileExtension) {
    case 'zip':
    case 'shp':
      return 'shapefile';
    case 'xml':
      return 'telepac';
    case 'kml':
      return 'kml';
    default:
      return 'telepac';
  }
}

export function buildImportedParcels(importedParcels) {
  const importedParcelsSuccess = importedParcels?.success || [];
  let importedParcelsError = [];
  if (importedParcels?.errors) {
    importedParcels.errors.forEach((importedParcelError) => {
      if (importedParcelError.parcel && importedParcelError.parcel.geometry_area) {
        importedParcelError.parcel.error = true;
        importedParcelsError.push(importedParcelError.parcel);
      }
    });
  }
  return importedParcelsSuccess.concat(importedParcelsError);
}
