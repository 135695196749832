import { combineReducers } from 'redux';
import {
  equipmentSessionsObservationsReducer,
  equipmentsObservationsReducer,
  parcelsObservationsReducer,
} from './assetsReducers';
import general from './general';

const observations = combineReducers({
  general,
  equipments: equipmentsObservationsReducer,
  parcels: parcelsObservationsReducer,
  equipmentsSessions: equipmentSessionsObservationsReducer,
});
export default observations;
