import { useContext, useMemo } from 'react';
import { buildActiveTracksFromEquipments } from '../../../../utils/equipments';
import { MapContext } from '../../contexts/map';
import { useStyleBuilders } from '../../effects';
import { useDrawTracksOnMap, useOverTracks, useTracksDefaultStyle } from '../Tracks/effects';
import { useDrawEquipmentOnMap } from './effects';

const Equipment = function ({ equipment, events }) {
  const { map } = useContext(MapContext);
  useDrawEquipmentOnMap(map, equipment, events);
  return null;
};

const Equipments = function ({
  equipments,
  events,
  hideActiveSession,
  trackEvents,
  overTrackID,
  overStyle,
  tracksWithoutFlags,
}) {
  const { map } = useContext(MapContext);
  const activeSessions = useMemo(
    () => (hideActiveSession ? null : buildActiveTracksFromEquipments(equipments)),
    [equipments, hideActiveSession],
  );
  const defaultTrackStyle = useTracksDefaultStyle();
  const styles = useMemo(() => [defaultTrackStyle], [defaultTrackStyle]);
  const trackStyles = useStyleBuilders(styles);
  const [tracksMap, decoratorsMap] = useDrawTracksOnMap(
    map,
    activeSessions,
    trackEvents,
    trackStyles,
    tracksWithoutFlags,
    false,
  );
  useOverTracks({ overTrackID, tracksMap, decoratorsMap, trackStyles, overStyle });
  return (
    <>
      {(equipments || []).map((e) => (
        <Equipment key={e.id} equipment={e} events={events} />
      ))}
    </>
  );
};

export default Equipments;
