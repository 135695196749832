import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import turfArea from '@turf/area';
import { UIContext } from '../../../contexts/ui';
import { formatArea } from '../../../utils';
import { L as Leaflet } from '../../../utils/LeafletOverrides';
import { useTranslatedInstructionsToDraw } from '../effects';

export function useGeolocation() {
  const [location, setLocation] = useState(null);
  const [status, setStatus] = useState('undetermined');
  const [asked, setAsked] = useState(null);

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation(position.coords);
      });
      setAsked(new Date());
    }
  }, [setLocation, setAsked]);

  useEffect(() => {
    if (!navigator.geolocation || !asked) return () => {};
    const id = navigator.geolocation.watchPosition(
      function () {
        setStatus('granted');
      },
      function (error) {
        if (error.code === error.PERMISSION_DENIED) {
          setStatus('denied');
        }
      },
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, [setStatus, asked]);

  return [getLocation, status, asked, location];
}

export function useCenterMapOnLocation(map, location) {
  useEffect(() => {
    if (!location || (!location.latitude && !location.longitude)) return () => {};
    map.flyTo([location.latitude, location.longitude], 16, { duration: 0.1 });
    return () => {};
  }, [location, map]);
}

export function useMeasure(map, line, hide) {
  const [enabled, setEnabled] = useState(false);

  useTranslatedInstructionsToDraw({
    key: 'polyline',
    start: 'DrawLine.tooltip_start',
    cont: 'DrawLine.tooltip_cont',
    end: 'DrawLine.tooltip_end',
  });

  const toggle = useCallback(() => {
    if (line._enabled) {
      line.disable();
    } else {
      line.enable();
    }
  }, [line]);

  const clear = useCallback(() => {
    line.disabled();
    setEnabled(false);
  }, [line]);

  const enable = useCallback((e) => {
    if (e?.layerType === 'polyline') {
      setEnabled(true);
    }
  }, []);
  const disable = useCallback((e) => {
    if (e?.layerType === 'polyline') {
      setEnabled(false);
    }
  }, []);

  useEffect(() => {
    if (!hide) {
      map.on(Leaflet.Draw.Event.DRAWSTART, enable);
      map.on(Leaflet.Draw.Event.DRAWSTOP, disable);
    }
    return () => {
      map.off(Leaflet.Draw.Event.DRAWSTART, enable);
      map.off(Leaflet.Draw.Event.DRAWSTOP, disable);
    };
  });

  return [toggle, enabled, clear];
}

export function useAreaMeasure(map, poly, hide) {
  const { units } = useContext(UIContext);
  const [enabled, setEnabled] = useState(false);
  const [drawnArea, setDrawnArea] = useState(null);

  useTranslatedInstructionsToDraw({
    key: 'polygon',
    start: 'DrawArea.tooltip_start',
    cont: 'DrawArea.tooltip_cont',
    end: 'DrawArea.tooltip_end',
  });

  const polyLayer = useRef(null);
  const areaMeasureLayer = useRef(new Leaflet.FeatureGroup());

  const clearDraw = useCallback(() => {
    if (polyLayer.current) {
      polyLayer.current.removeFrom(areaMeasureLayer.current);
    }
    polyLayer.current = null;
    setDrawnArea(null);
    areaMeasureLayer.current.removeFrom(map);
  }, [map]);

  const toggle = useCallback(() => {
    if (polyLayer.current?.editing?._enabled || poly._enabled) {
      clearDraw();
      poly.disable();
      setEnabled(false);
    } else {
      poly.enable();
    }
  }, [poly, polyLayer, clearDraw]);

  const enable = useCallback((e) => {
    if (e?.layerType === 'polygon') {
      setEnabled(true);
    }
  }, []);

  const onCreated = useCallback(
    (e) => {
      const polyFromEvent = e && e.layer;
      if (polyFromEvent && e.layerType === 'polygon') {
        map.addLayer(areaMeasureLayer.current);
        areaMeasureLayer.current.addLayer(polyFromEvent);
        polyLayer.current = polyFromEvent;
        polyFromEvent.editing.enable();
        const poly = polyFromEvent.toGeoJSON();
        let area = turfArea(poly);
        setDrawnArea({ area: formatArea(area, units.area, true), valid: true });
      }
    },
    [map, units.area],
  );

  const onEdit = useCallback(
    (e) => {
      if (e.poly) {
        const poly = e.poly.toGeoJSON();
        let area = turfArea(poly);
        setDrawnArea({
          area: formatArea(area, units.area, true),
          valid: true,
        });
      }
    },
    [units.area],
  );

  useEffect(() => {
    if (!polyLayer.current || !hide) return () => {};
    polyLayer.current.on('pm:edit', onEdit);
    polyLayer.current.on('pm:markerdrag', onEdit);
    return () => {
      polyLayer.current.off('pm:edit', onEdit);
      polyLayer.current.off('pm:markerdrag', onEdit);
      if (polyLayer.current?.editing?._enabled) {
        clearDraw();
      }
    };
  }, [onEdit, clearDraw, hide]);

  useEffect(() => {
    if (!hide) {
      map.on(Leaflet.Draw.Event.DRAWSTART, enable);
      map.on(Leaflet.Draw.Event.CREATED, onCreated);
      map.on(Leaflet.Draw.Event.EDITVERTEX, onEdit);
    }
    return () => {
      map.off(Leaflet.Draw.Event.DRAWSTART, enable);
      map.off(Leaflet.Draw.Event.CREATED, onCreated);
      map.off(Leaflet.Draw.Event.EDITVERTEX, onEdit);
    };
  });

  useEffect(() => {
    return () => {
      clearDraw();
    };
  }, [clearDraw]);

  return [toggle, enabled, setEnabled, drawnArea, clearDraw];
}
