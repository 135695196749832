import { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import isArray from 'lodash-es/isArray';
import { normalize as normalizr } from 'normalizr';
import removeAccents from 'remove-accents';
import { AdminIcon, DriverIcon, FarmerIcon } from '@karnott/icons';
import { USER_CONSTANTS } from '../constants';
import { I18n } from '../i18n';

export const preNormalize = (input, map = {}) => {
  // Recursively normalize nested array of entities, passing dictionary param.
  if (isArray(input)) {
    return input.map((o) => preNormalize(o, map));
  }

  if (!input || typeof input !== 'object') {
    return input;
  }

  const inputKeys = Object.keys(input);

  if (inputKeys.length === 0) {
    return input;
  }

  return inputKeys.reduce((acc, key) => {
    if ((key === 'geometry_area' || key === 'track') && typeof input[key] === 'string') {
      try {
        acc[key] = JSON.parse(input[key]);
      } catch (error) {
        console.log(`Error ${key} => ${error}`, input);
        acc[key] = null;
      }
    } else {
      acc[key] = preNormalize(input[key], map);
    }

    return acc;
  }, {});
};

export const normalize = (input, type) => {
  const preNormalized = preNormalize(input);
  if (preNormalized) {
    return normalizr(preNormalized, type);
  }
};

export const round = (value, decimals = 2) => +value.toFixed(decimals);

export const formatDistance = (input, units = USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI, withUnit = false) => {
  const unit = typeof units === 'string' ? units : units.distance;

  if (isNaN(input)) {
    return '-';
  }
  if (unit === USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.IMPERIAL) {
    return `${input ? `${round((input * 0.621371192) / 1000, 2)}${withUnit ? ' mi' : ''}` : '-'}`;
  }
  return `${input ? `${round(input / 1000, 2)}${withUnit ? ' km' : ''}` : '-'}`;
};

export const formatSpeed = (input, units = USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI, withUnit = false) => {
  const unit = typeof units === 'string' ? units : units.distance;

  if (isNaN(input)) {
    return '-';
  }
  if (unit === USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.IMPERIAL) {
    return `${input ? `${round((input * 0.621371192) / 3600 / 1000, 2)}${withUnit ? ' mi/h' : ''}` : '-'}`;
  }
  return `${input ? `${round((input * 3600) / 1000, 2)}${withUnit ? ' km/h' : ''}` : '-'}`;
};

export const getAreaUnit = (unit = USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI) => {
  return unit === USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.IMPERIAL ? 'ac' : 'ha';
};

export const formatArea = (input, units = USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI, withUnit = false) => {
  const unit = typeof units === 'string' ? units : units.area;

  const value = input
    ? round(
        (input * (unit === USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.IMPERIAL ? USER_CONSTANTS.UNIT_RATIO.AC_TO_HA : 1)) /
          10000,
        2,
      )
    : undefined;

  return `${value ? `${value}${withUnit ? ` ${getAreaUnit(unit)}` : ''}` : '-'}`;
};

export const convertAreaToSquaredMeters = (input, inputUnit = USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI) => {
  return inputUnit === USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI ? input * 10000 : input * 4046.86;
};

export const formatUnit = (input) => `${input ? `${input}` : '-'}`;

export const formatProductivity = (input, units = USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.SI, withUnit = false) => {
  const unit = typeof units === 'string' ? units : units.distance;

  if (isNaN(input)) {
    return '-';
  }
  if (unit === USER_CONSTANTS.UNITS.DISTANCE_AND_AREA.IMPERIAL) {
    return `${input ? `${round((input * 0.621371192 * 3600) / 1000, 2)}${withUnit ? ' ac/h' : ''}` : '-'}`;
  }
  return `${input ? `${round((input * 3600) / 10000, 2)}${withUnit ? ' ha/h' : ''}` : '-'}`;
};

const formatDurationCompact = (duration, precision = 1, withUnit) =>
  `${round(duration / 3600, precision)}${withUnit ? ' h' : ''}`;

export const formatDuration = (duration, units = USER_CONSTANTS.UNITS.TIME.DECIMAL, withUnit = false) => {
  const unit = typeof units === 'string' ? units : units.time;

  if (isNaN(duration)) {
    return '-';
  }

  if (unit === USER_CONSTANTS.UNITS.TIME.STANDARD) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60).toLocaleString('fr-FR', {
      minimumIntegerDigits: 2,
    });
    return `${hours}h${minutes}min`;
  }
  if (unit === USER_CONSTANTS.UNITS.TIME.DECIMAL2) {
    return formatDurationCompact(duration, 2, withUnit);
  }
  return formatDurationCompact(duration, 1, withUnit);
};

export const getTotal = (array = [], key) => array.reduce((acc, curr) => acc + curr[key], 0);

export const APPLICATION_TYPE = {
  csv: 'text/csv;charset=utf-8',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const fromKmPerHourToMperS = (kmh) => (kmh * 1000) / 3600;

export const fromMperStoKmPerHour = (ms) => {
  if (ms === 0) {
    return 0;
  }
  return Math.round((ms / 1000) * 3600);
};

export const isEmail = (value) => value && /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i.test(value);

export const clusterContains = (cluster = {}, filter = '') =>
  removeAccents(cluster.name || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase());

export const cropContains = (crop = {}, filter = '') =>
  removeAccents(crop.name || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase());

export const parcelTypeContains = (parcel = {}, filter = '') =>
  removeAccents(parcel.category || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase()) ||
  removeAccents(parcel.status || '')
    .toLowerCase()
    .includes(removeAccents(filter).toLowerCase());

export const userContains = (user = {}, filter = '') => {
  const concatName = `${user.fullname}${user.first_name}${user.last_name}${user.email}`;
  return removeAccents(concatName).toLowerCase().includes(removeAccents(filter).toLowerCase());
};

export const getUserIconRegardingRole = (role) => {
  switch (role) {
    case USER_CONSTANTS.ROLE.ADMIN:
      return AdminIcon;
    case USER_CONSTANTS.ROLE.DRIVER:
    case USER_CONSTANTS.ROLE.SEASONAL_DRIVER:
      return DriverIcon;
    default:
      return FarmerIcon;
  }
};

export function areIDsEquals(a, b) {
  return a && b && a.id && b.id && a.id.toString() === b.id.toString();
}

export function areStatusEquals(a, b) {
  return a.status === b.status;
}

export function areEntityEquals(a, b) {
  return a.entity === b.entity;
}

export function sortInUseFirst(a) {
  return a.status === 'IN_USE' ? -1 : 1;
}

export function useInitialScroll({ offset, container }) {
  const [initialScrolled, setInitialScrolled] = useState(false);
  useEffect(() => {
    if (!initialScrolled && container) {
      setInitialScrolled(true);
      container.scrollTo({ left: 0, top: offset, behavior: 'auto' });
    }
  }, [initialScrolled, offset, container]);
}

export const sortAlphaBeta = (a, b) => {
  return removeAccents(a).toLowerCase().trim() >= removeAccents(b).toLowerCase().trim() ? 1 : -1;
};
export const sortByPosition = (a, b) => {
  return (a.position || 0) - (b.position || 0);
};

export const getItemLabel = (item, splitValue) => {
  let label = item?.label || '';
  if (splitValue && label.length > splitValue) {
    return label.slice(0, splitValue) + '...';
  }
  return label;
};
export const getLabel = (selection) => (selection?.length ? selection[0].label : null);

export const observationCallbackTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';

export function convertDDToDMS(D, lng) {
  const dir = D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N';
  const deg = 0 | (D < 0 ? (D = -D) : D);
  const min = 0 | (((D += 1e-9) % 1) * 60);
  const sec = (0 | (((D * 60) % 1) * 6000)) / 100;
  return `${deg}°${min}'${sec}"${dir}`;
}

export function getStaticHTMLFromComponent(component) {
  if (component) {
    return renderToStaticMarkup(component);
  }
}

export const showPartners = (app) => app && app !== 'karnott' && app !== 'Backend';

/**
 * @param   {Record<string, any>}                             obj Object to map
 * @param   {(value: any, key: string, index: number) => any} fn  Callback applied on each entry
 *
 * @returns {Record<string, any>}                                 A new mapped object
 */
export function objectMap(obj, fn) {
  return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

/**
 * Formats the page title as {context} – Karnott
 *
 * @param {string} context The page title
 */
export function formatPageTitle(context) {
  return context ? `${context} — Karnott` : I18n.t('Commons.page_title');
}

/** Function that does nothing, useful for example to preserve identity in default props */
export function noop() {}
