export const FAQ_LINKS = {
  INDEX: 'https://aide.karnott.fr',
  IMPORT_PARCEL: 'http://aide.karnott.fr/fr/articles/8015891-comment-recuperer-vos-parcellaires',
  ADD_PARCEL: 'https://aide.karnott.fr/fr/articles/5813831-comment-ajouter-ou-dessiner-les-parcelles',
  ROLE: 'https://aide.karnott.fr/fr/articles/5817418-que-signifient-les-differents-roles',
  FARMHOUSE: 'https://aide.karnott.fr/fr/articles/5813887-comment-ajouter-un-corps-de-ferme',
  EQUIPMENT_WIDTH:
    'https://aide.karnott.fr/fr/articles/5534986-comment-bien-parametrer-vos-materiels-largeur-categorie',
};

export const KARNOTT_LINKS = {
  QUOTATION: 'https://www.karnott.fr/demande-devis',
  SPONSORSHIP: 'https://www.karnott.fr/parrainage-filleul',

  AVENT_CALENDAR:
    'https://karnott.notion.site/ec1c9f3b7d6d4b27a8e6547f6f978c7c?v=35630201d4b04cc48787a1a8a0af4237&pvs=4',
};

export const TASK_UPSELL_LINKS = {
  VIDEO: 'https://www.youtube.com/embed/02249k3KBCw?si=9kL17kN9FqrvhF0E&autoplay=1',
};
