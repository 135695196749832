import omit from 'lodash-es/omit';
import remove from 'lodash-es/remove';
import union from 'lodash-es/union';
import uniq from 'lodash-es/uniq';
import { Actions } from '../actions';

const initialState = {
  areImportedParcelsFetching: false,
  clusterParcelsByParcelId: {},
  creationErrors: [],
  farmhouseMode: false,
  importedParcels: {},
  initialParcelZoom: null,
  initialParcelCenter: {
    latitude: null,
    longitude: null,
  },
  isFetching: false,
  isThereGfrParcelsFetched: false,
  parcelById: {},
  parcelsIds: [],
  parcelsToDelete: [],
  requestedZones: [],
  cropHistory: {},
  parcelsWorksites: [],
  parcelsWorksitesTracks: null,
  parcelWorksites: null,
  parcelWorksitesTracks: null,
  parcelActiveWorksitesTracks: null,
  parcelConsumptions: null,
  lastFetchedParcel: null,
};

const parcels = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.PARCELS_REQUEST:
    case Actions.PARCEL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.PARCELS_SUCCESS: {
      const parcelsIds = union(state.parcelsIds, payload.result);
      return {
        ...state,
        isFetching: false,
        parcelById: {
          ...state.parcelById,
          ...payload.entities.parcel,
        },
        parcelsIds: parcelsIds,
      };
    }
    case Actions.GFR_PARCELS_SUCCESS: {
      const gfrParcelsIds = union(state.parcelsIds, payload.result);
      return {
        ...state,
        isFetching: false,
        isThereGfrParcelsFetched: true,
        parcelById: {
          ...state.parcelById,
          ...payload.entities.parcel,
        },
        parcelsIds: gfrParcelsIds,
      };
    }
    case Actions.PARCEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        parcelById: {
          ...state.parcelById,
          ...payload.entities.parcel,
        },
        parcelsIds: union(state.parcelsIds, payload.result),
        lastFetchedParcel: Object.values(payload.entities.parcel)[0],
      };
    case Actions.PARCEL_FOCUS:
      return {
        ...state,
        focusedParcel: payload,
      };
    case Actions.PARCEL_CREATION_SUCCESS:
      return {
        ...state,
        parcelsIds: state.parcelsIds.concat(payload.result),
        parcelById: {
          ...state.parcelById,
          ...payload.entities.parcel,
        },
      };
    case Actions.PARCEL_CREATION_ERRORS:
      return {
        ...state,
        creationErrors: payload,
      };
    case Actions.PARCEL_CREATION_CLEAR_ERRORS:
      return {
        ...state,
        creationErrors: [],
      };
    case Actions.PARCEL_EDIT_SUCCESS:
      return {
        ...state,
        parcelById: {
          ...state.parcelById,
          [payload.result]: {
            ...state.parcelById[payload.result],
            ...payload.entities.parcel[payload.result],
          },
        },
        lastFetchedParcel:
          state.lastFetchedParcel?.id === payload.result
            ? payload.entities.parcel[payload.result]
            : state.lastFetchedParcel,
      };
    case Actions.MULTI_PARCEL_DELETE_SUCCESS: {
      const { parcelIds } = payload;
      let parcelByIDUpdated = {};
      Object.values(state.parcelById).forEach((parcel) => {
        if (!parcelIds?.includes(parcel.id)) {
          parcelByIDUpdated[parcel.id] = parcel;
        }
      });
      return {
        ...state,
        parcelsIds: state.parcelsIds.filter((id) => !parcelIds?.includes(id)),
        parcelById: parcelByIDUpdated,
        lastFetchedParcel: parcelIds?.includes(state.lastFetchedParcel?.id) ? undefined : state.lastFetchedParcel,
      };
    }
    case Actions.PARCEL_DELETE_SUCCESS:
      return {
        ...state,
        parcelsIds: state.parcelsIds.filter((id) => id !== action.payload),
        parcelById: omit(state.parcelById, action.payload),
        lastFetchedParcel: state.lastFetchedParcel?.id === payload.result ? undefined : state.lastFetchedParcel,
      };
    case Actions.REQUEST_IMPORT_PARCELS:
      return {
        ...state,
        areImportedParcelsFetching: true,
      };
    case Actions.RECEIVE_IMPORTED_PARCELS: {
      payload.errors.forEach((error, index) => {
        error.parcel.id = -(index + 1);
      });
      const importedParcelByID = {};
      payload.success.forEach((p) => {
        importedParcelByID[p.id] = p;
      });
      const importedParcelIDs = payload.success.map((p) => p.id);
      return {
        ...state,
        parcelsIds: [...state.parcelsIds, ...importedParcelIDs],
        parcelById: {
          ...state.parcelById,
          ...importedParcelByID,
        },
        areImportedParcelsFetching: false,
        importedParcels: payload,
      };
    }
    case Actions.REINIT_IMPORTED_PARCELS:
      return {
        ...state,
        areImportedParcelsFetching: false,
        importedParcels: {},
      };
    case Actions.REQUEST_GFR_PARCELS:
      return {
        ...state,
        requestedZones: state.requestedZones.concat([payload]),
      };
    case Actions.SELECT_MULTIPLE_PARCELS_TO_DELETE:
      return {
        ...state,
        parcelsToDelete: uniq(state.parcelsToDelete.concat(payload)),
      };
    case Actions.DESELECT_MULTIPLE_PARCELS_TO_DELETE: {
      const mptd = [...state.parcelsToDelete];
      payload.forEach((id) => {
        const index = mptd.indexOf(id);
        mptd.splice(index, 1);
      });

      return {
        ...state,
        parcelsToDelete: uniq(mptd),
      };
    }
    case Actions.GFR_PARCELS_CREATION_SUCCESS:
      // remove gfr parcels which have been allocated in order to not display it
      remove(state.parcelsIds, (pId) => payload.gfrParcelIds.indexOf(pId) !== -1);
      payload.gfrParcelIds.forEach((gfrParcelId) => {
        delete state.parcelById[gfrParcelId];
      });
      return {
        ...state,
        parcelsIds: union(state.parcelsIds, payload.normalizedResult.result),
        parcelById: {
          ...state.parcelById,
          ...payload.normalizedResult.entities.parcel,
        },
      };
    case Actions.PARCEL_HISTORY_SUCCESS: {
      const { clusterParcelsByParcelId } = state;
      const { parcelId, data } = payload;
      const clusterParcelsObj = (data.entities && data.entities.clusterParcel) || {};
      const clusterParcelsIds = data.result || [];
      return {
        ...state,
        isFetching: false,
        clusterParcelsByParcelId: {
          ...clusterParcelsByParcelId,
          [parcelId]: clusterParcelsIds.map((id) => clusterParcelsObj[id]),
        },
      };
    }
    case Actions.PARCEL_CROPS_HISTORY_SUCCESS:
      return {
        ...state,
        cropHistory: {
          ...state.cropHistory,
          [payload.parcelID]: payload.history,
        },
      };
    case Actions.CROP_DELETED: {
      const newParcelByID = { ...state.parcelById };
      state.parcelsIds.forEach((parcelID) => {
        if (newParcelByID[parcelID].current_crop_id && newParcelByID[parcelID].current_crop_id === payload)
          newParcelByID[parcelID].current_crop_id = undefined;
      });
      return { ...state, parcelById: newParcelByID };
    }
    case Actions.PARCELS_WORKSITES_SUCCESS:
      if (!payload) return state;
      return {
        ...state,
        parcelsWorksites: payload,
      };
    case Actions.PARCELS_WORKSITES_TRACKS_SUCCESS:
      if (!payload) return state;
      return {
        ...state,
        parcelsWorksitesTracks: payload,
      };
    case Actions.PARCEL_WORKSITES_SUCCESS:
      return {
        ...state,
        parcelWorksites: payload,
      };
    case Actions.PARCEL_WORKSITES_TRACKS_SUCCESS:
      return {
        ...state,
        parcelWorksitesTracks: payload,
      };
    case Actions.PARCEL_ACTIVE_WORKSITES_TRACKS_SUCCESS:
      return {
        ...state,
        parcelActiveWorksitesTracks: payload,
      };
    case Actions.PARCEL_CONSUMPTIONS_SUCCESS:
      return {
        ...state,
        parcelConsumptions: payload,
      };
    case Actions.PARCEL_LINK_EXISTING_TAG: {
      const parcel = state.parcelById[payload.parcelId];
      return {
        ...state,
        parcelById: {
          ...state.parcelById,
          [payload.parcelId]: { ...parcel, tags: [...(parcel.tags || []), payload.tag] },
        },
        lastFetchedParcel:
          state.lastFetchedParcel?.id === payload.parcelId
            ? { ...state.lastFetchedParcel, tags: [...(state.lastFetchedParcel.tags || []), payload.tag] }
            : state.lastFetchedParcel,
      };
    }
    case Actions.PARCEL_UNLINK_TAG: {
      const parcel = state.parcelById[payload.parcelId];
      return {
        ...state,
        parcelById: {
          ...state.parcelById,
          [payload.parcelId]: { ...parcel, tags: (parcel.tags || []).filter((tag) => tag.id !== payload.tagId) },
        },
        lastFetchedParcel:
          state.lastFetchedParcel?.id === payload.parcelId
            ? {
                ...state.lastFetchedParcel,
                tags: (state.lastFetchedParcel.tags || []).filter((tag) => tag.id !== payload.tagId),
              }
            : state.lastFetchedParcel,
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default parcels;
