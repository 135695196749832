import { v4 } from 'uuid';
import { Actions } from '.';

export const clearSnacks = () => ({
  type: Actions.SNACK_CLEAR_ALL,
});

export const sendSnack = (snack) => ({
  type: Actions.SNACK_QUEUE,
  payload: {
    ...snack,
    id: v4(),
  },
});

export const sendImportantSnack = (snack) => ({
  type: Actions.SNACK_POP_IMPORTANT,
  payload: {
    ...snack,
    id: v4(),
  },
});

export const dismissSnack = (snackId) => ({
  type: Actions.SNACK_DISMISS,
  payload: snackId,
});
