import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { WarningIcon } from '../';

type Props = {
  size: number;
  backgroundColor: string;
  children: ReactNode;
  circled?: boolean;
  healthIssue?: boolean;
};

export default function CircledIcon({ size, backgroundColor, children, circled = false, healthIssue = false }: Props) {
  return (
    <Container
      $size={size}
      {...{
        backgroundColor,
        circled,
      }}
    >
      {circled && healthIssue && (
        <WarningIconContainer>
          <WarningIcon size={size / 3.25} backgroundColor={colors('red', 500)} color={'white'} />
        </WarningIconContainer>
      )}
      {children}
    </Container>
  );
}

const Container = styled.div<{
  $size: number;
  circled: boolean;
  backgroundColor: string;
}>`
  position: relative;
  font-size: 0;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  background-color: ${({ circled, backgroundColor }) => (circled ? backgroundColor : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  > svg * {
    transition: all 0.1s linear;
  }
`;

const WarningIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: -3%;
`;
