export const MarkerIcon = function (props) {
  const defaultHeight = 50;
  const stroke = 4;
  let { length, size, color, withoutBorder, withoutArrow } = props;
  return (
    <svg
      viewBox={`0 0 ${defaultHeight * length} ${defaultHeight}`}
      height={`${size || 24}`}
      style={{ overflow: 'visible' }}
      {...props}
    >
      <g>
        {withoutBorder ? null : (
          <rect
            fill="#FFF"
            x="0"
            y="0"
            height={`${defaultHeight}`}
            width={`${defaultHeight * length}`}
            rx={`${defaultHeight / 2}`}
            ry={`${defaultHeight / 2}`}
          ></rect>
        )}
        <rect
          fill={`${color || '#B1B1B1'}`}
          x={`${stroke}`}
          y={`${stroke}`}
          height={`${defaultHeight - stroke * 2}`}
          width={`${defaultHeight * length - stroke * 2}`}
          rx={`${(defaultHeight - stroke * 2) / 2}`}
          ry={`${(defaultHeight - stroke * 2) / 2}`}
        ></rect>
        {withoutArrow ? null : (
          <g>
            <path
              fill="#FFF"
              d={`M${(defaultHeight * length) / 2 - 8} ${defaultHeight - 2} L${(defaultHeight * length) / 2 + 8} ${
                defaultHeight - 2
              } L${(defaultHeight * length) / 2} ${defaultHeight + 5} Z`}
            />
            <ellipse
              opacity="0.25"
              fill="#141105"
              cx={`${(defaultHeight * length) / 2}`}
              cy={`${defaultHeight + 6}`}
              rx="5.9"
              ry="0.2"
            />
          </g>
        )}
      </g>
    </svg>
  );
};
