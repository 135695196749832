import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { elevation } from '@karnott/theme';

function useHover<ElementType extends HTMLElement>(
  disabled = false,
): [ref: React.RefObject<ElementType>, hovered: boolean] {
  const [value, setValue] = useState(false);

  const ref = useRef<ElementType>(null);

  const handleMouseEnter = useCallback(() => !disabled && setValue(true), [disabled]);
  const handleMouseLeave = useCallback(() => !disabled && setValue(false), [disabled]);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseover', handleMouseEnter);
        node.removeEventListener('mouseout', handleMouseLeave);
      };
    }
    return () => {};
  }, [handleMouseEnter, handleMouseLeave]);

  return [ref, value];
}

function useOpenCloseState(
  isOpen: boolean,
): [opened: boolean, open: () => void, close: () => void, toggle: () => void] {
  const [opened, setOpened] = useState(isOpen);

  const open = useCallback(() => setOpened(true), [setOpened]);
  const close = useCallback(() => setOpened(false), [setOpened]);
  const toggle = useCallback(() => setOpened(!opened), [opened, setOpened]);

  return [opened, open, close, toggle];
}

function useContainerSize(
  container: React.RefObject<HTMLElement>,
  onResize?: () => void,
): [width: number, height: number] {
  const [[width, height], setWidthHeight] = useState([0, 0]);

  useEffect(() => {
    if (!container.current) return () => {};
    const localContainer = container.current;

    function resize() {
      if (localContainer) {
        const width = localContainer.clientWidth;
        const height = localContainer.clientHeight;
        onResize?.();
        setWidthHeight([width, height]);
      }
    }

    const to = setTimeout(resize, 100);
    window.addEventListener('resize', resize);
    const resizeObserver = new ResizeObserver(resize);

    resizeObserver.observe(localContainer);

    return () => {
      if (localContainer) {
        resizeObserver.unobserve(localContainer);
      }
      to && clearTimeout(to);
      window.removeEventListener('resize', resize);
    };
  }, [container, onResize]);

  return [width, height];
}

type Movement = {
  dx: number;
  dy: number;
};

function useDraggableContainer<ElementType extends HTMLElement>(
  onMove?: (movement: Movement) => void,
  onDragEnd?: (movement: Movement) => void,
): [containerRef: React.RefObject<ElementType>] {
  const container = useRef<ElementType>(null);
  const isHoldingDown = useRef(false);

  const onMouseDown = useCallback(() => {
    isHoldingDown.current = true;
  }, [isHoldingDown]);

  const onMouseUp = useCallback(
    (event: MouseEvent) => {
      if (isHoldingDown.current) {
        onDragEnd && onDragEnd({ dx: event.movementX, dy: event.movementY });
      }
      isHoldingDown.current = false;
    },
    [isHoldingDown, onDragEnd],
  );

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (isHoldingDown.current) {
        onMove && onMove({ dx: event.movementX, dy: event.movementY });
      }
    },
    [isHoldingDown, onMove],
  );

  useEffect(() => {
    if (!container || !container.current) return () => {};

    const localContainer = container.current;
    localContainer.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      localContainer.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [container, onMouseDown, onMouseUp, onMouseMove]);

  return [container];
}

function useElevation({ added, elevated }: { added: boolean; elevated: boolean }) {
  return useMemo(() => elevation({ added, elevated }), [added, elevated]);
}

export const UIHooks = {
  useContainerSize,
  useDraggableContainer,
  useElevation,
  useHover,
  useOpenCloseState,
};
