import { L as Leaflet } from '../utils/LeafletOverrides';

export const GOOGLE_MUTANT_TILES = Leaflet.gridLayer.googleMutant({
  type: 'hybrid',
});

export const IGN_LAYER = Leaflet.tileLayer(
  'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
  {
    attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
    minZoom: 0,
    maxZoom: 19,
    apikey: 'pratique',
    format: 'image/png',
    style: 'normal',
  },
);

export const IGN_MAX_ZOOM = 19;
export const GOOGLE_MAX_ZOOM = 20;
