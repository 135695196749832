import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import 'leaflet.markercluster';
import 'leaflet.vectorgrid';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { colors, shadeHex } from '@karnott/colors';
import { LAYER_API_BASE } from '../../../../constants';
import { CLUSTER_CONSTANTS } from '../../../../constants/clusterConstants';
import { PARCELS_CONSTANTS, PARCEL_COLOR_ID } from '../../../../constants/parcelsConstants';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { PluginsContext } from '../../../../contexts/plugins';
import { UIContext } from '../../../../contexts/ui';
import { color, zIndex } from '../../../../ui/theme';
import { formatArea, getStaticHTMLFromComponent, getTotal } from '../../../../utils';
import { L as Leaflet, setSmoothZoom } from '../../../../utils/LeafletOverrides';
import { isPluginActivated } from '../../../../utils/clusters';
import { parcelHasCropColor, parcelHasTagColor } from '../../../../utils/parcels';
import { useDebounce } from '../../effects';
import './parcel-marker-cluster.css';
import { buildParcelStyle } from './style';
import { ParcelName } from './tooltip';

const defaultIcon = Leaflet.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

Leaflet.Marker.prototype.options.icon = defaultIcon;

export const parcelPropertiesDataExtractor = (p, history) => {
  return {
    parcel_id: p.id,
    cluster_id: p.cluster_id,
    crop_id: p.current_crop_id,
    unique_tag_id: Array.isArray(p.tags) && p.tags.length === 1 && p.tags.at(0).id,
    current_tenant_cluster: p.current_tenant_cluster?.name,
    error: p.error,
    category: p.category,
    area: p.area,
    status: p.status,
    name: p.name,
    crop_name: p.crop?.name,
    crop_color: p.crop ? `#${p.crop?.color}` : null,
    action: () => history.push(`/parcels/${p.id}`),
    isViti: p.cluster?.activities?.length === 1 && p.cluster.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.viticulture,
    hasCropPlugin: isPluginActivated(p.cluster, CLUSTER_CONSTANTS.PLUGINS_NAMES.crop_management),
  };
};

export function useDrawParcelsOnMap(
  map,
  parcels,
  events,
  styleBuilder,
  dataExtractor,
  parcelOverTracks,
  parcelsCanvasRenderer,
  parcelsNamesDraggable,
  forceNoMarkerCluster,
) {
  const { units, showParcelNames } = useContext(UIContext);
  const mapBounds = map.getBounds();
  const [mapZoom, setMapZoom] = useState(map.getZoom());

  useEffect(() => {
    map.on('zoom', () => setMapZoom(map.getZoom()));
  }, [map]);

  const zoomIn = useCallback(() => map.zoomIn(), [map]);
  const [parcelNamesPositionMap, setParcelNamesPositionMap] = useState({});
  // sorting of parcels in order to draw parcels with "INACTIVE" status first
  const sortedParcels = useMemo(() => {
    return parcels?.sort((a, b) => {
      if (a.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE && b.status !== PARCELS_CONSTANTS.STATUS.RENT_INACTIVE) {
        return -1;
      }
      if (a.status !== PARCELS_CONSTANTS.STATUS.RENT_INACTIVE && b.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE) {
        return 1;
      }
      return 0;
    });
  }, [parcels]);

  const patchedParcels = useMemo(() => {
    return (
      sortedParcels &&
      sortedParcels.map((p, index) => {
        const geo = typeof p.geometry_area === 'string' ? JSON.parse(p.geometry_area) : p.geometry_area;
        return {
          ...geo,
          properties: {
            ...geo.properties,
            index,
            ...dataExtractor(p),
          },
        };
      })
    );
  }, [sortedParcels, dataExtractor]);

  const markerModeActivated = useMemo(() => {
    if (forceNoMarkerCluster) {
      return false;
    }
    return patchedParcels && patchedParcels.length > PARCELS_CONSTANTS.PARCEL_MARKER_CLUSTER.THRESHOLD;
  }, [forceNoMarkerCluster, patchedParcels]);

  const showMarkers = useMemo(() => {
    if (forceNoMarkerCluster) {
      return false;
    }
    return Math.round(mapZoom) < PARCELS_CONSTANTS.PARCEL_MARKER_CLUSTER.ZOOM_LIMIT;
  }, [mapZoom, forceNoMarkerCluster]);

  const markers = useMemo(
    () =>
      Leaflet.markerClusterGroup({
        disableClusteringAtZoom: PARCELS_CONSTANTS.PARCEL_MARKER_CLUSTER.ZOOM_LIMIT,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: true,
        animate: true,
        maxClusterRadius: 100,
        chunkedLoading: true,
        iconCreateFunction: function (cluster) {
          const children = cluster.getAllChildMarkers();
          const area = getTotal(children, 'area');
          return Leaflet.divIcon({
            html: '<div class="marker-cluster"><div>' + formatArea(area, units.area, true) + '</div></div>',
            className: '',
            iconSize: new Leaflet.Point(60, 60),
          });
        },
        polygonOptions: {
          color: color('cyan'),
          weight: 4,
        },
      }),
    [units.area],
  );

  useEffect(() => {
    if (markerModeActivated) {
      const layers = [];
      (patchedParcels || []).forEach((p) => {
        const { bbox } = p;
        if (bbox) {
          const longitude = (bbox[0] + bbox[2]) / 2;
          const latitude = (bbox[1] + bbox[3]) / 2;
          const marker = Leaflet.marker(Leaflet.latLng(latitude, longitude), {
            icon: Leaflet.divIcon({
              html:
                '<div class="marker-cluster"><div>' + formatArea(p.properties.area, units.area, true) + '</div></div>',
              className: '',
              iconSize: new Leaflet.Point(60, 60),
            }),
          });
          marker.on('click', () => zoomIn());
          marker.area = p.properties.area;
          layers.push(marker);
        }
      });
      markers.addLayers(layers);
    }
    return () => {
      markers.clearLayers();
    };
  }, [markers, patchedParcels, zoomIn, units.area, markerModeActivated]);

  const filterParcelCB = useCallback(
    (p) => {
      if (!p.bbox) return true;
      const corner1 = Leaflet.latLng(p.bbox[1], p.bbox[0]),
        corner2 = Leaflet.latLng(p.bbox[3], p.bbox[2]),
        bounds = Leaflet.latLngBounds(corner1, corner2);
      return mapBounds.intersects(bounds);
    },
    [mapBounds],
  );

  const parcelsToDisplay = useMemo(() => {
    if (patchedParcels) {
      if (!markerModeActivated) {
        return patchedParcels.filter(filterParcelCB);
      } else {
        if (showMarkers) return;
        return patchedParcels.filter(filterParcelCB);
      }
    }
  }, [patchedParcels, filterParcelCB, showMarkers, markerModeActivated]);

  const geoJsonToDisplayLayer = useMemo(() => {
    if (markerModeActivated && showMarkers) return;
    const opts = {
      type: 'parcel',
      pane: 'overlayPane',
      style: styleBuilder,
      onEachFeature: (f, l) => {
        const theParcel = parcels?.find(({ id }) => id === f.properties.parcel_id);
        Object.keys(events || {}).forEach((event) => {
          l.off(event, () => events[event](theParcel, l));
          l.on(event, () => events[event](theParcel, l));
        });
      },
    };
    if (parcelsCanvasRenderer) {
      opts.renderer = parcelsCanvasRenderer;
    }
    return Leaflet.geoJSON(parcelsToDisplay, opts);
  }, [parcelsToDisplay, styleBuilder, events, parcels, showMarkers, markerModeActivated, parcelsCanvasRenderer]);

  useEffect(() => {
    if (geoJsonToDisplayLayer) {
      if (parcelOverTracks) {
        geoJsonToDisplayLayer.setStyle({
          pane: 'shadowPane',
        });
      } else {
        geoJsonToDisplayLayer.setStyle({
          pane: 'overlayPane',
        });
      }
    }
  }, [geoJsonToDisplayLayer, parcelOverTracks]);

  const parcelNames = useMemo(() => {
    if (!geoJsonToDisplayLayer) return;
    const names = [];
    geoJsonToDisplayLayer.eachLayer((l) => {
      const properties = l.feature?.geometry?.properties;
      const name = properties?.name;
      const clusterID = properties?.cluster_id;
      const parcelID = properties?.parcel_id;
      const theHtml = getStaticHTMLFromComponent(<ParcelName {...{ name }} />);
      if (clusterID && l.feature?.geometry?.bbox?.every((el) => el !== 0)) {
        const initialPos = parcelNamesPositionMap[parcelID] || l.getBounds().getCenter();
        const marker = Leaflet.marker(initialPos, {
          draggable: parcelsNamesDraggable,
          icon: Leaflet.divIcon({
            className: 'map-parcel-names',
            html: theHtml,
            bgPos: [0, -5],
          }),
        });
        names.push(marker);
        marker.on('dragend', () => {
          setParcelNamesPositionMap((m) => ({
            ...m,
            [parcelID]: marker.getLatLng(),
          }));
        });
      }
    });
    return Leaflet.layerGroup(names);
  }, [geoJsonToDisplayLayer, parcelsNamesDraggable, parcelNamesPositionMap]);

  const showNames = useMemo(() => {
    return showParcelNames && mapZoom > PARCELS_CONSTANTS.PARCEL_NAMES.ZOOM_LIMIT;
  }, [mapZoom, showParcelNames]);

  useEffect(() => {
    if (!map) return () => {};
    if (markerModeActivated && showMarkers) {
      map.addLayer(markers);
    } else {
      if (geoJsonToDisplayLayer) {
        geoJsonToDisplayLayer.addTo(map);
      }
      if (showNames) {
        map.addLayer(parcelNames);
      }
    }
    return () => {
      markers && markers.removeFrom(map);
      geoJsonToDisplayLayer && geoJsonToDisplayLayer.removeFrom(map);
      parcelNames && parcelNames.removeFrom(map);
    };
  }, [geoJsonToDisplayLayer, map, showMarkers, markers, parcelNames, showNames, markerModeActivated]);

  useEffect(() => {
    geoJsonToDisplayLayer && geoJsonToDisplayLayer.setStyle(styleBuilder);
  }, [geoJsonToDisplayLayer, styleBuilder]);

  return geoJsonToDisplayLayer;
}

export function useDrawVectorLayerOnMap(map, dataset, onParcelClick, metadata) {
  const [layer, setLayer] = useState(null);

  const style = useParcelsDefaultStyle();
  const [metadataString, setMetadataString] = useState(metadata ? JSON.stringify(metadata) : '');
  const setMetadataStringDebounced = useDebounce(setMetadataString, 500);

  useEffect(() => {
    setMetadataStringDebounced(metadata ? JSON.stringify(metadata) : '');
  }, [metadata, setMetadataStringDebounced]);

  useEffect(() => {
    const toggleSmoothZoom = () => {
      /* vector tiles don’t work when using smooth zoom */
      if (map.getZoom() > (dataset.minZoom ?? 14)) {
        setSmoothZoom(false, map);
      } else {
        setSmoothZoom(true, map);
      }
    };

    let vectorGrid = null;
    if (dataset) {
      const datasetParams = new URLSearchParams(
        metadataString ? { include_metadata: true, metadata: metadataString } : {},
      );
      datasetParams.set('dataset_ids', dataset.id);
      vectorGrid = Leaflet.vectorGrid
        .protobuf(`${LAYER_API_BASE}/geometries/tiles/{z}/{x}/{y}?${datasetParams.toString()}`, {
          rendererFactory: Leaflet.svg.tile,
          vectorTileLayerStyles: {
            [dataset.label]: { ...style({ geometry: { properties: {} } }), fill: true },
          },
          getFeatureId: (f) => f.properties.id,
          interactive: true,
          fetchOptions: {
            headers: {
              Accept: 'application/x-protobuf',
            },
          },
          minZoom: dataset.minZoom ?? 14,
        })
        .setZIndex(zIndex('ui_map_overlay'))
        .addTo(map);

      vectorGrid && map.on('zoomend', toggleSmoothZoom);
    }

    setLayer(vectorGrid);

    return () => {
      vectorGrid && vectorGrid.removeFrom(map);
      setLayer(null);
      map.off('zoomend', toggleSmoothZoom);
      setSmoothZoom(true, map);
    };
  }, [map, dataset, style, metadataString]);

  const memoizedGeoJSONs = useRef({});

  const getParcelGeoJSONFromId = useCallback(async (id) => {
    if (id && memoizedGeoJSONs.current[id]) {
      return memoizedGeoJSONs.current[id];
    }

    try {
      const response = await fetch(`${LAYER_API_BASE}/geometries/${id}`, {
        headers: { Accept: 'application/geo+json' },
      });
      const data = await response.json();
      memoizedGeoJSONs.current[id] = { data };
      return { data };
    } catch (e) {
      console.error(e);
      return { error: e };
    }
  }, []);

  useEffect(() => {
    async function onClick(e) {
      const properties = e.propagatedFrom.properties;

      if (properties.id) {
        const response = await getParcelGeoJSONFromId(properties.id);
        if (response.error || response.data.features.length !== 1) {
          return null;
        }
        const parcel = JSON.parse(JSON.stringify(response.data.features[0]));

        // convert MultiPolygon to Polygon
        if (parcel.geometry.type === 'MultiPolygon') {
          parcel.geometry.type = 'Polygon';
          parcel.geometry.coordinates = parcel.geometry.coordinates[0];
        }

        parcel.id = parcel.properties.id;
        parcel.area = parseFloat(parcel.properties.area);
        parcel.name = parcel.properties.name;
        parcel.farmhouse = false;

        return onParcelClick(parcel, layer);
      }
    }

    if (layer) {
      layer.on('click', onClick);
    }

    return () => {
      if (layer) {
        layer.off('click', onClick);
      }
    };
  }, [getParcelGeoJSONFromId, layer, onParcelClick]);

  return layer;
}

export function useParcelsCoords(parcels = []) {
  return useMemo(() => {
    return (
      parcels.length &&
      parcels.map((p) => {
        if (!p.geometry_area.bbox) {
          const theCoords = p.geometry_area.coordinates[0];
          if (theCoords?.length) {
            return theCoords.map((c) => [c[1], c[0]]);
          }
        }
        return [
          [p.geometry_area.bbox[1], p.geometry_area.bbox[0]],
          [p.geometry_area.bbox[3], p.geometry_area.bbox[2]],
        ];
      })
    );
  }, [parcels]);
}

export function useParcelsDefaultStyle() {
  return useCallback((feature) => buildParcelStyle(feature.geometry.properties), []);
}

export function useParcelsHighlightStyle(targetIds = []) {
  const style = useCallback(
    (feature) => {
      const { farmhouse, parcel_id } = feature.geometry.properties;
      if (parcel_id !== undefined && targetIds && targetIds.includes(parcel_id.toString())) {
        const colorValue = farmhouse ? 'yellow' : 'green';
        return {
          strokeColor: colors(colorValue, 'light'),
          color: colors(colorValue),
          fillColor: colors(colorValue),
          fillOpacity: 0.2,
          weight: 2,
          zIndex: 203,
        };
      } else {
        return {};
      }
    },
    [targetIds],
  );
  return [style];
}

export function useParcelsOverrideStyle({ focusedIDs, activeColoration }) {
  const { hasOverallCropManagementPlugin } = useContext(PluginsContext);
  const anonStyle = useMemo(() => {
    return {
      fillColor: colors('orange', 'light'),
      fillOpacity: 1,
      weight: 6,
      zIndex: 203,
    };
  }, []);

  const errorStyle = useMemo(() => {
    return {
      fillColor: color('red', 'light'),
      fillOpacity: 1,
      weight: 6,
      zIndex: 203,
    };
  }, []);

  const farmhouseStyle = useMemo(() => {
    return {
      fillColor: color('yellow', 'light'),
      fillOpacity: 0.7,
      weight: 6,
      zIndex: 203,
    };
  }, []);

  const baseStyle = useMemo(() => {
    return {
      fillColor: color('cyan', 'light'),
      fillOpacity: 1,
      weight: 6,
      zIndex: 203,
    };
  }, []);

  const rentOrInactiveStyle = useMemo(() => {
    return {
      fillColor: color('snow', 'dark'),
      fillOpacity: 1,
      weight: 6,
      zIndex: 203,
    };
  }, []);

  const featureStyle = useCallback(
    (feature) => {
      const { cluster_id, crop_id, unique_tag_id, error, parcel_id, category, status } = feature.geometry.properties;
      if (parcel_id !== undefined && focusedIDs && focusedIDs.includes(parcel_id.toString())) {
        for (const item of activeColoration?.items || []) {
          if (
            activeColoration.id === PARCEL_COLOR_ID.CROPS
              ? category !== PARCELS_CONSTANTS.CATEGORY.FARMHOUSE &&
                hasOverallCropManagementPlugin &&
                parcelHasCropColor(crop_id, item)
              : parcelHasTagColor(unique_tag_id, item)
          ) {
            return {
              fillColor: shadeHex(item.color, 0.1),
              fillOpacity: 1,
              weight: 6,
            };
          }
        }
        if (category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
          return farmhouseStyle;
        }
        if (!cluster_id) {
          return anonStyle;
        }
        if (error) {
          return errorStyle;
        }
        if (category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
          return farmhouseStyle;
        }
        if (
          status === PARCELS_CONSTANTS.STATUS.RENT_OVER ||
          status === PARCELS_CONSTANTS.STATUS.BEING_RENT ||
          status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE
        ) {
          return rentOrInactiveStyle;
        }
        return baseStyle;
      } else {
        for (const item of activeColoration?.items || []) {
          if (
            activeColoration.id === PARCEL_COLOR_ID.CROPS
              ? category !== PARCELS_CONSTANTS.CATEGORY.FARMHOUSE &&
                hasOverallCropManagementPlugin &&
                parcelHasCropColor(crop_id, item)
              : parcelHasTagColor(unique_tag_id, item)
          ) {
            return {
              fillColor: item.color,
              fillOpacity: 0.7,
            };
          }
        }
        if (category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
          return { ...farmhouseStyle, weight: 2 };
        }
      }
      return {};
    },
    [
      focusedIDs,
      hasOverallCropManagementPlugin,
      activeColoration?.id,
      activeColoration?.items,
      farmhouseStyle,
      baseStyle,
      errorStyle,
      rentOrInactiveStyle,
      anonStyle,
    ],
  );

  return useMemo(() => [featureStyle], [featureStyle]);
}

export function useSelectedParcelsFocusedStyle(targetIds = []) {
  const style = useCallback(
    (feature) => {
      const { error, farmhouse, parcel_id } = feature.geometry.properties;
      let colorValue = farmhouse ? 'yellow' : 'green';
      if (error) {
        colorValue = 'red';
      }
      return parcel_id !== undefined && targetIds && targetIds.includes(parcel_id.toString())
        ? {
            strokeColor: colors(colorValue, 'light'),
            color: colors(colorValue, 'light'),
            fillColor: colors(colorValue, 'light'),
            fillOpacity: 0.6,
            weight: 2,
            zIndex: 203,
          }
        : {};
    },
    [targetIds],
  );
  return [style];
}

export function useParcelTooltipLabels() {
  const { t } = useContext(I18nContext);
  return useMemo(
    () => ({
      crop_undefined: t('Crop.undefined'),
      being_rent: t('ParcelDetails.being_rent'),
      been_rent: t('ParcelDetails.been_rent'),
      see: t('Commons.see'),
    }),
    [t],
  );
}
