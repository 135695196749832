import { useMemo } from 'react';
import { colors } from '@karnott/colors';
import { PARCELS_CONSTANTS } from '@karnott/constants';
import { FarmhouseIcon, KIcon, ParcelRentIcon, PlotsIcon } from '@karnott/icons';

function usePlotCardArea(plotArea: number) {
  return useMemo(() => {
    return `${(plotArea * 0.0001).toFixed(2)} ha`;
  }, [plotArea]);
}

type Plot = {
  cluster_id: number;
  status: (typeof PARCELS_CONSTANTS.STATUS)[keyof typeof PARCELS_CONSTANTS.STATUS];
  category: (typeof PARCELS_CONSTANTS.CATEGORY)[keyof typeof PARCELS_CONSTANTS.CATEGORY];
};

function usePlotIconAndColor({ cluster_id, status, category }: Plot): [Icon: KIcon, backgroundColor: string] {
  return useMemo(() => {
    let Icon = PlotsIcon;
    let backgroundColor = colors('cyan');
    if (category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE) {
      Icon = FarmhouseIcon;
      backgroundColor = colors('yellow');
    }
    if (status === PARCELS_CONSTANTS.STATUS.RENT_OVER || status === PARCELS_CONSTANTS.STATUS.BEING_RENT) {
      Icon = ParcelRentIcon;
      backgroundColor = colors('grey');
    }
    if (status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE) {
      backgroundColor = colors('sepia');
    }
    if (cluster_id === 0) {
      backgroundColor = colors('orange');
    }
    return [Icon, backgroundColor];
  }, [category, cluster_id, status]);
}

export const PlotHooks = {
  usePlotCardArea,
  usePlotIconAndColor,
};
