import { useContext } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { I18nContext } from '../contexts/I18nProvider';
import { CustomLink } from '../ui';
import { ui } from '../ui/theme';
import Modal, { ModalFooter, ModalTitle } from './Modal';

const WarningBanner = styled.div`
  width: 100%;
  padding: 8px 32px 8px 32px;
  background-color: ${ui('warning')};
  color: ${colors('grey')};
`;

const LeaveIE = function ({ isOpen }) {
  const { t } = useContext(I18nContext);

  return (
    <Modal contentLabel="leaveIE" isOpen={isOpen}>
      <ModalTitle>{t('LeaveIE.modal_title')}</ModalTitle>
      <WarningBanner>{t('LeaveIE.warning_label')}</WarningBanner>
      <ModalFooter>
        <CustomLink href="https://www.google.fr/chrome/">{t('LeaveIE.go_chrome')}</CustomLink>
        <CustomLink href="https://www.mozilla.org/fr/firefox/">{t('LeaveIE.go_ff')}</CustomLink>
      </ModalFooter>
    </Modal>
  );
};

export default LeaveIE;
