import { Actions } from '../actions';

const initialState = {
  tagsByClusterId: {},
};

const tags = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.GET_TAG:
      return {
        ...state,
        tagsByClusterId: {
          ...state.tagsByClusterId,
          [payload.tag.cluster_id]: [
            ...(state.tagsByClusterId[payload.tag.cluster_id] || []).filter((t) => t.id !== payload.tag.id),
            payload.tag,
          ],
        },
      };
    case Actions.GET_TAGS: {
      const tagsByClusterId = {};
      for (const tag of payload.tags) {
        tagsByClusterId[tag.cluster_id] = [...(tagsByClusterId[tag.cluster_id] || []), tag];
      }
      return {
        ...state,
        tagsByClusterId,
      };
    }
    case Actions.DELETE_TAG: {
      const tagsByClusterId = {};
      for (const clusterId in state.tagsByClusterId) {
        tagsByClusterId[clusterId] = state.tagsByClusterId[clusterId].filter((t) => t.id !== payload.id);
      }
      return {
        ...state,
        tagsByClusterId,
      };
    }
    default:
      return state;
  }
};

export default tags;
