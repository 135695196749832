import omit from 'lodash-es/omit';
import { Actions } from '../actions';

const initialState = {
  cropByID: {},
  cropIDs: [],
};

const crops = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.CROPS_SUCCESS:
    case Actions.CROP_CREATION_SUCCESS:
      return {
        ...state,
        cropByID: {
          ...state.cropByID,
          ...payload.entities.crop,
        },
        cropIDs: [...state.cropIDs, ...payload.result],
      };
    case Actions.CROP_UPDATED:
      return {
        ...state,
        cropByID: {
          ...state.cropByID,
          [payload.id]: payload,
        },
      };
    case Actions.CROP_DELETED:
      return {
        ...state,
        cropIDs: [...state.cropIDs].filter((id) => id !== payload),
        cropByID: omit({ ...state.cropByID }, payload),
      };
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default crops;
